import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";

const ReplyFAQ = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
}) => {
  const [ begin, setbegin ] = useState(true);
  const [ table_faq, setTable_faq ] = useState([]);
  const [ repId, setRepId ] = useState("");
  const [ repqe, setRepqe ] = useState("");
  const [ repus, setRepus ] = useState("");
  const [ repan, setRepan ] = useState("");
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.post(server + "/get_faqs").then((response) => {
      if (response.data.status === "success") {
        setTable_faq(response.data.data);
      }
    });
    setbegin(false);
  }, [ begin ]);
  const submitAction = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    const data = {
      user: id,
      answ: repan,
      date: moment(Date.now()).format("YYYY-MM-DD"),
      fqid: repId,
      ansId: uuidV4(),
    };
    axios.post(server + "/ans_qa", data).then((response) => {
      if (response.data.status === "success") {
        setRepan("");
        setRepId("");
        setRepus("");
        setRepqe("");
        MsgShow(
          "success",
          "success",
          50,
          "ok",
          "Your Reply has been submitted successfully. You can reply again once they replied to your answer.",
          "Reply Submitted"
        );
        setbegin(true);
      }
    });
  };
  let haveMsg = 0;
  table_faq.map((val) => {
    if (val.reps === 0) {
      haveMsg = haveMsg + 1;
    }
  });
  return (
    <>
      <div className="table-bar">
        {haveMsg > 0 ? (
          <h4>Your Questions</h4>
        ) : (
          <h4>You Have No Questions.</h4>
        )}

        <div className="table-cr">
          {table_faq.map((val) => {
            if (val.reps === 0) {
              return (
                <div className="table-row" key={val.id}>
                  <div className="title">{val.user}</div>
                  <div className="details">
                    <p>{val.ques}</p>
                    <p className="btn-cir-bar">
                      <span
                        className="btn-cir"
                        onClick={() => {
                          setRepId(val.fqid);
                          setRepqe(val.ques);
                          setRepus(val.user);
                        }}
                      >
                        <i className="fa fa-comments"></i>
                        {val.reps}
                      </span>
                      <span
                        className="btn-cir"
                        onClick={() => {
                          //deleteQuestion(val.id);
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </span>
                    </p>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      {repId.length > 0 ? (
        <form action="" method="post" id="formcd">
          <h4>Reply to question of {repus}.</h4>
          <p>{repqe}</p>
          <div class="flex-bar">
            <div class="input txta">
              <i class="fa fa-question"></i>
              <textarea
                name="details"
                id="details"
                placeholder="Tell us your question short & sweet."
                onChange={(e) => {
                  setRepan(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="btn-bar">
            <div
              className="btn-bx"
              onClick={() => {
                submitAction();
              }}
            >
              <i className="fa fa-comment"></i>
              Reply
            </div>
          </div>
        </form>
      ) : null}
    </>
  );
};

export default ReplyFAQ;
