import React from "react";
import "../styles/Back.css";

function Background() {

  var arry = "2025".split("");
  var arry2 = "HAPPY NEW YEAR!!!".split("");
  var arry3 = "WWW.DSPACE.ICU".split("");

  function updateText(text) {

    let delay = 200;

    let h1 = document.getElementById("animated");
    if (isNaN(h1)) {
      h1.innerHTML = text
        .split("")
        .map(letter => {
          return `<p>` + letter + `</p>`;
        })
        .join("");
      Array.from(h1.children).forEach((span, index) => {
        setTimeout(() => {
          span.classList.add("wavy");
        }, index * 60 + delay);
      });

    }

  }
  updateText("Happy New Year 2025");
  return (
    <div className="background">
      <div id="bg-theme">
        <div className="fly">
          <div className="plane">
          </div>
          <div className="flag">
            <h1 id="animated"></h1>
          </div>
        </div>
        <div className="mounts"></div>
        <div className="clouds"></div>
      </div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
export default Background;
