import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Img from "../assets/audio.gif";
import Img2 from "../assets/video.gif";
import { v4 as uuidV4 } from "uuid";

const AddMedia = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
  cypher,
}) => {
  const [ up, setUp ] = useState(false);
  const [ Del, setDel ] = useState(false);
  const [ cat, setcat ] = useState("photo");
  const [ Alid, setAlid ] = useState("");
  const [ fLen, setFlen ] = useState(0);
  const [ Alna, setAlna ] = useState("");
  const [ files, setFiles ] = useState([]);
  const [ albums, setAlbums ] = useState([]);
  const [ photos, setphotos ] = useState([]);
  const [ vphotos, setVphotos ] = useState([]);
  const [ videos, setVideos ] = useState([]);
  const [ audios, setAudios ] = useState([]);
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };
  useEffect(() => {
    axios.post(server + "/get_all_albums").then((response) => {
      if (response.data.status === "success") {
        const res = decrypt(cypher, response.data.data);
        const conv = JSON.parse(res);
        setAlbums(conv);
      }
    });
    axios.post(server + "/get_all_videos").then((response) => {
      if (response.data.status === "success") {
        const res = decrypt(cypher, response.data.data);
        const conv = JSON.parse(res);
        setVideos(conv);
      }
    });
    axios.post(server + "/get_all_audios").then((response) => {
      if (response.data.status === "success") {
        const res = decrypt(cypher, response.data.data);
        const conv = JSON.parse(res);
        setAudios(conv);
      }
    });
    axios.post(server + "/photos_all").then((response) => {
      if (response.data.status === "success") {
        const res = decrypt(cypher, response.data.data);
        const conv = JSON.parse(res);
        setphotos(conv);
      }
    });
  }, []);
  const viewAlbumFiles = (e) => {
    setVphotos(photos.filter((p) => {
      return e === p.fid;
    }))
  };
  function getvideoBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setvideoFilesList(file.name, file.type, file.size, reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function getaudioBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setaudioFilesList(file.name, file.type, file.size, reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setFilesList(file.name, file.type, file.size, reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  let vfi = [];
  function setvideoFilesList(name, type, size, file) {
    vfi.push({
      name: name,
      type: type,
      size: size,
      file: file,
      vid: uuidV4(),
    });
    if (vfi.length > 0) {
      MsgShow(
        "success",
        "success",
        5000,
        "ok",
        `You have selected ${vfi.length} video files to upload. Your files will be upload to videos database quickly.`,
        "Upload Started"
      );
      setFiles(vfi);
      setUp(true);
    }
  }
  let afi = [];
  function setaudioFilesList(name, type, size, file) {
    afi.push({
      name: name,
      type: type,
      size: size,
      file: file,
      aid: uuidV4(),
    });
    if (afi.length > 0) {
      MsgShow(
        "success",
        "success",
        5000,
        "ok",
        `You have selected ${afi.length} audio files to upload. Your files will be upload to audio database quickly.`,
        "Upload Started"
      );
      setFiles(afi);
      setUp(true);
    }
  }
  let kk = [];
  function setFilesList(name, type, size, file) {
    kk.push({
      name: name,
      type: type,
      size: size,
      file: file,
      pid: uuidV4(),
    });
    if (kk.length > 0) {
      MsgShow(
        "success",
        "success",
        5000,
        "ok",
        `You have selected ${kk.length} files to upload. Your files will be upload to ${Alna} Album quickly.`,
        "Upload Started"
      );
      setFiles(kk);
      setUp(true);
    }
  }
  const uploadvideoFiles = () => {
    if (files.length === fLen) {
      const data = {
        files: JSON.stringify(files),
      };
      axios.post(server + "/add_videos", data).then((response) => {
        if (response.data.status === "success") {
          MsgShow(
            "success",
            "success",
            5000,
            "ok",
            `You have uploaded ${files.length} video files. Your files will be shown in video's section.`,
            "Upload Completed"
          );
          setFiles([]);
          setUp(false);
          document.getElementById("upload").value = "";
          axios.post(server + "/get_all_videos").then((response) => {
            if (response.data.status === "success") {
              const res = decrypt(cypher, response.data.data);
              const conv = JSON.parse(res);
              setVideos(conv);
            }
          });
        }
      });
    }
  };
  const uploadaudioFiles = () => {
    if (files.length === fLen) {
      const data = {
        files: JSON.stringify(files),
      };
      axios.post(server + "/add_audios", data).then((response) => {
        if (response.data.status === "success") {
          MsgShow(
            "success",
            "success",
            5000,
            "ok",
            `You have uploaded ${files.length} audio files. Your files will be shown in audio's section.`,
            "Upload Completed"
          );
          setFiles([]);
          setUp(false);
          document.getElementById("upload").value = "";
          axios.post(server + "/get_all_audios").then((response) => {
            if (response.data.status === "success") {
              const res = decrypt(cypher, response.data.data);
              const conv = JSON.parse(res);
              setAudios(conv);
            }
          });
        }
      });
    }
  };
  const uploadFiles = () => {
    if (files.length === fLen) {
      const data = {
        fid: Alid,
        files: JSON.stringify(files),
      };
      axios.post(server + "/add_photos", data).then((response) => {
        if (response.data.status === "success") {
          MsgShow(
            "success",
            "success",
            5000,
            "ok",
            `You have uploaded ${files.length} files. Your files will be shown in ${Alna} Album.`,
            "Upload Completed"
          );
          setFiles([]);
          setUp(false);
          document.getElementById("upload").value = "";
          axios.post(server + "/photos_all").then((response) => {
            if (response.data.status === "success") {
              const res = decrypt(cypher, response.data.data);
              const conv = JSON.parse(res);
              setphotos(conv);
              viewAlbumFiles(Alid);
            }
          });
        }
      });
    }
  };
  return (
    <>
      <div class="headline">
        <ul className="selector">
          <li
            onLoad={() => {
              if (cat === "photo") {
                document.querySelector(".photo").classList.add("active");
              }
            }}
            className="photo active"
            onClick={() => {
              setcat("photo");
              setAlid("");
              setAlna("");
              document.querySelector(".photo").classList.add("active");
              document.querySelector(".video").classList.remove("active");
              document.querySelector(".audio").classList.remove("active");
              document.querySelector(".table").classList.remove("active");
            }}
          >
            <i className="fa fa-photo"></i>
          </li>
          <li
            className="video"
            onClick={() => {
              setcat("video");
              setAlid("");
              setAlna("");
              document.querySelector(".photo").classList.remove("active");
              document.querySelector(".video").classList.add("active");
              document.querySelector(".audio").classList.remove("active");
              document.querySelector(".table").classList.remove("active");
            }}
          >
            <i className="fa fa-film"></i>
          </li>
          <li
            className="audio"
            onClick={() => {
              setcat("audio");
              setAlid("");
              setAlna("");
              document.querySelector(".photo").classList.remove("active");
              document.querySelector(".video").classList.remove("active");
              document.querySelector(".audio").classList.add("active");
              document.querySelector(".table").classList.remove("active");
            }}
          >
            <i className="fa fa-headphones"></i>
          </li>
        </ul>
      </div>
      {cat === "video" ? (
        <>
          <div className="photo-bar">
            {videos
              ? videos.map((video) => {
                return (
                  <div className="photo-card" key={video.id}>
                    <div className="image">
                      <img src={Img2} alt="" />
                      {Del ? (
                        <div
                          className="del-btn"
                          onClick={() => {
                            const data = {
                              id: video.id,
                            };
                            axios
                              .post(server + "/delete_video", data)
                              .then((response) => {
                                if (response.data.status === "success") {
                                  MsgShow(
                                    "success",
                                    "success",
                                    5000,
                                    "ok",
                                    `Video file has been deleted successfully.`,
                                    "Video Deleted"
                                  );
                                  axios.post(server + "/get_all_videos").then((response) => {
                                    if (response.data.status === "success") {
                                      const res = decrypt(cypher, response.data.data);
                                      const conv = JSON.parse(res);
                                      setVideos(conv);
                                    }
                                  });
                                }
                              });
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
              : null}
            {up ? (
              <div
                className="photo-card"
                onClick={() => {
                  uploadvideoFiles();
                }}
              >
                <div className="image">
                  <div className="text-panel">
                    <i
                      className="fa fa-cloud-upload"
                      style={{ fontSize: "2rem", marginBottom: "10px" }}
                    ></i>
                    <h4>Upload</h4>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="photo-card"
                onClick={() => {
                  document.getElementById("upload").click();
                }}
              >
                <div className="image">
                  <div className="text-panel">
                    <i
                      className="fa fa-plus-square"
                      style={{ fontSize: "2rem", marginBottom: "10px" }}
                    ></i>
                    <h4>Video</h4>
                  </div>
                </div>
              </div>
            )}
          </div>
          <input
            type="file"
            name="upload"
            id="upload"
            multiple
            onChange={(e) => {
              setFlen(e.target.files.length);
              for (var i = 0; i < e.target.files.length; i++) {
                getvideoBase64(e.target.files[ i ]);
              }
            }}
          />
        </>
      ) : cat === "audio" ? (
        <>
          <div className="photo-bar">
            {audios
              ? audios.map((audio) => {
                return (
                  <div className="photo-card" key={audio.id}>
                    <div className="image">
                      <img src={Img} alt="" />
                      {Del ? (
                        <div
                          className="del-btn"
                          onClick={() => {
                            const data = {
                              id: audio.id,
                            };
                            axios
                              .post(server + "/delete_audio", data)
                              .then((response) => {
                                if (response.data.status === "success") {
                                  MsgShow(
                                    "success",
                                    "success",
                                    5000,
                                    "ok",
                                    `Audio file has been deleted successfully.`,
                                    "Audio Deleted"
                                  );
                                  axios.post(server + "/get_all_audios").then((response) => {
                                    if (response.data.status === "success") {
                                      const res = decrypt(cypher, response.data.data);
                                      const conv = JSON.parse(res);
                                      setAudios(conv);
                                    }
                                  });
                                }
                              });
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
              : null}
            {up ? (
              <div
                className="photo-card"
                onClick={() => {
                  uploadaudioFiles();
                }}
              >
                <div className="image">
                  <div className="text-panel">
                    <i
                      className="fa fa-cloud-upload"
                      style={{ fontSize: "2rem", marginBottom: "10px" }}
                    ></i>
                    <h4>Upload</h4>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="photo-card"
                onClick={() => {
                  document.getElementById("upload").click();
                }}
              >
                <div className="image">
                  <div className="text-panel">
                    <i
                      className="fa fa-plus-square"
                      style={{ fontSize: "2rem", marginBottom: "10px" }}
                    ></i>
                    <h4>Audio</h4>
                  </div>
                </div>
              </div>
            )}
          </div>
          <input
            type="file"
            name="upload"
            id="upload"
            multiple
            onChange={(e) => {
              setFlen(e.target.files.length);
              for (var i = 0; i < e.target.files.length; i++) {
                getaudioBase64(e.target.files[ i ]);
              }
            }}
          />
        </>
      ) : cat === "photo" ? (
        <div className="photo-bar">
          {albums
            ? albums.map((album) => {
              return (
                <div
                  className="photo-card"
                  key={album.id}
                  onClick={() => {
                    viewAlbumFiles(album.fid);
                    setAlid(album.fid);
                    setAlna(album.name);
                  }}
                >
                  <div className="image">
                    <img src={album.fp} alt="" />
                    {Del ? (
                      <div
                        className="del-btn"
                        onClick={() => {
                          const data = {
                            id: album.fid,
                          };
                          axios
                            .post(server + "/delete_album", data)
                            .then((response) => {
                              if (response.data.status === "success") {
                                MsgShow(
                                  "success",
                                  "success",
                                  5000,
                                  "ok",
                                  `Photo Album has been deleted with its content successfully.`,
                                  "Album Deleted"
                                );
                                axios
                                  .post(server + "/get_all_albums")
                                  .then((response) => {
                                    if (response.data.status === "success") {
                                      const res = decrypt(cypher, response.data.data);
                                      const conv = JSON.parse(res);
                                      setAlbums(conv);
                                    }
                                  });
                              }
                            });
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
            : null}
          <div
            className="photo-card"
            onClick={() => {
              setShow("add_albums");
            }}
          >
            <div className="image">
              <div className="text-panel">
                <i
                  className="fa fa-plus-square"
                  style={{ fontSize: "2rem", marginBottom: "10px" }}
                ></i>
                <h4>Albums</h4>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Alna.length > 0 ? (
        <>
          <h4>Photos in "{Alna}" Album</h4>
          <div className="photo-bar">
            {vphotos.length > 0
              ? vphotos.map((photo) => {
                return (
                  <div className="photo-card" key={photo.id}>
                    <div className="image">
                      <img src={photo.file} alt="" />
                      {Del ? (
                        <div
                          className="del-btn"
                          onClick={() => {
                            const data = {
                              id: photo.id,
                            };
                            axios
                              .post(server + "/delete_photo", data)
                              .then((response) => {
                                if (response.data.status === "success") {
                                  MsgShow(
                                    "success",
                                    "success",
                                    5000,
                                    "ok",
                                    `Photo has been deleted successfully.`,
                                    "Photo Deleted"
                                  );
                                  setphotos(photos.filter((v) => {
                                    return v.id !== photo.id;
                                  }))
                                  setVphotos(photos.filter((p) => {
                                    return Alid === p.fid;
                                  }))
                                }
                              });
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
              : null}
            {up ? (
              <div
                className="photo-card"
                onClick={() => {
                  uploadFiles();
                }}
              >
                <div className="image">
                  <div className="text-panel">
                    <i
                      className="fa fa-cloud-upload"
                      style={{ fontSize: "2rem", marginBottom: "10px" }}
                    ></i>
                    <h4>Upload</h4>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="photo-card"
                onClick={() => {
                  document.getElementById("upload").click();
                }}
              >
                <div className="image">
                  <div className="text-panel">
                    <i
                      className="fa fa-plus-square"
                      style={{ fontSize: "2rem", marginBottom: "10px" }}
                    ></i>
                    <h4>Photos</h4>
                  </div>
                </div>
              </div>
            )}
          </div>
          <input
            type="file"
            name="upload"
            id="upload"
            multiple
            onChange={(e) => {
              setFlen(e.target.files.length);
              for (var i = 0; i < e.target.files.length; i++) {
                getBase64(e.target.files[ i ]);
              }
            }}
          />
        </>
      ) : null}
      <div className="btn-bar">
        <div
          className="btn-bx no"
          onClick={() => {
            if (Del) {
              setDel(false);
            } else {
              setDel(true);
            }
          }}
        >
          <i className="fa fa-trash"></i>
          Delete
        </div>

        <div className="btn-bx close" onClick={() => setShow("")}>
          <i className="fa fa-close"></i>
          Close
        </div>
      </div>
    </>
  );
};
export default AddMedia;
