import React, { useEffect } from 'react'

const Welcome = () => {

    useEffect(() => {
        setTimeout(() => {
            OpenWindow();
        }, 5000);
    }, [])
    const OpenWindow = () => {
        var left = document.getElementById("slice");
        var wel = document.getElementById("wel");
        var right = document.getElementById("slice-b");
        left.style.transform = "translateX(-150%)";
        right.style.transform = "translateX(100%)";
        setTimeout(() => {
            wel.style.display = "none";
        }, 500);
    }
    return (
        <div className='welcome' id='wel'>
            <div className="slice" id='slice'>
                <div className="round" id='click'>
                    <img src="DLM.png" alt="" />
                </div>
                <div className="welnote">
                    <h1>wdhqfndajka</h1>
                </div>
            </div>
            <div className="slice" id='slice-b'>

            </div>
        </div>
    )
}

export default Welcome