import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Products() {
  return (
    <div className="wall">
      <div className="post">
        <div className="logo">
          <img src="DLM.png" alt="Logo" />
        </div>
        <h4>Products and status</h4>
        <p>
          At www.dspace.icu, we pride ourselves on offering a diverse range of
          cutting-edge multimedia products designed to meet the unique needs of
          our clients. From state-of-the-art software solutions to visually
          stunning digital content, our products are crafted with precision and
          creativity. Dive into our product offerings and discover how we can
          help you achieve your digital goals with excellence and innovation.
        </p>
        <div className="btn-bar">
          <Link className="btn-bx" to={"/view_products"}>
            <i className="fa fa-rocket"></i> products
          </Link>
          <Link className="btn-bx" to={"/services"}>
            <i className="fa fa-cogs"></i> services
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Products;
