import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Icon from "../assets/video.gif";
import Icon2 from "../assets/audio.gif";
import { Link } from "react-router-dom";
import ViewAlbumMedia from "./ViewAlbumMedia";
import ViewMedia from "./ViewMedia";
import axios from "axios";

const Albums = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
  cypher,
}) => {
  const [ show, setShow ] = useState("photo");
  const [ album, setAlbum ] = useState("");
  const [ photos, setPhotos ] = useState([]);
  const [ albums, setAlbums ] = useState([]);
  const [ videos, setVideos ] = useState([]);
  const [ audios, setAudios ] = useState([]);
  const [ saved, setSaved ] = useState([]);
  const [ Alpass, setAlpass ] = useState("");
  const [ viewFile, setViewFile ] = useState("");
  const [ category, setCategory ] = useState("");
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };
  var user = JSON.parse(localStorage.getItem("dsp-user"));


  useEffect(() => {
    if (saved.length >= 0) {
      const data = {
        uid: user.uid,
        saved: JSON.stringify(saved),
      };
      axios.post(server + "/update_saved", data).then((response) => {
        if (response.data.status === "success") {
          return;
        } else {
          MsgShow(
            "error",
            "error",
            50,
            "close",
            `${response.data.msg}`,
            "Problem saving media"
          );
        }
      });
    }
  }, [ saved ]);
  useEffect(() => {
    MsgShow(
      "normal",
      "load",
      50,
      "",
      "Please wait while we load our media.",
      "Loading media"
    );
    if (localStorage.getItem("dsp-user").toString().length > 0) {
      const data = { uid: user.uid };
      axios.post(server + "/saved", data).then((response) => {
        if (response.data.status === "success") {
          const res = decrypt(cypher, response.data.data);
          const conv = JSON.parse(res);
          var data = JSON.parse(conv[ 0 ].list);
          setSaved(data);
        }
      });

      axios.post(server + "/get_all_videos").then((response) => {
        if (response.data.status === "success") {
          const res = decrypt(cypher, response.data.data);
          const conv = JSON.parse(res);
          setVideos(conv);
        }
      });
      axios.post(server + "/get_all_audios").then((response) => {
        if (response.data.status === "success") {
          const res = decrypt(cypher, response.data.data);
          const conv = JSON.parse(res);
          setAudios(conv);
        }
      });
      axios.post(server + "/photos_all").then((response) => {
        if (response.data.status === "success") {
          const res = decrypt(cypher, response.data.data);
          const conv = JSON.parse(res);
          setPhotos(conv);
          axios.post(server + "/get_all_albums").then((response) => {
            if (response.data.status === "success") {
              const res2 = decrypt(cypher, response.data.data);
              const conv2 = JSON.parse(res2);
              setAlbums(conv2);
              MsgShow(
                "success",
                "success",
                5000,
                "ok",
                "Media loading completed successfully.",
                "Dspace Media"
              );
            }
          });
        }
      });
    } else {
      axios.post(server + "/get_all_videos").then((response) => {
        if (response.data.status === "success") {
          const res = decrypt(cypher, response.data.data);
          const conv = JSON.parse(res);
          setVideos(conv);
        }
      });
      axios.post(server + "/get_all_audios").then((response) => {
        if (response.data.status === "success") {
          const res = decrypt(cypher, response.data.data);
          const conv = JSON.parse(res);
          setAudios(conv);
        }
      });
      axios.post(server + "/photos_all").then((response) => {
        if (response.data.status === "success") {
          const res = decrypt(cypher, response.data.data);
          const conv = JSON.parse(res);
          setPhotos(conv);
          axios.post(server + "/get_all_albums").then((response) => {
            if (response.data.status === "success") {
              const res2 = decrypt(cypher, response.data.data);
              const conv2 = JSON.parse(res2);
              setAlbums(conv2);
              MsgShow(
                "success",
                "success",
                5000,
                "ok",
                "Media loading completed successfully.",
                "Dspace Media"
              );
            }
          });
        }
      });
    }

  }, []);
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div className="headline">
          <Link className="back-btn" to={"/media"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <ul className="selector">
            <li
              className="photo active"
              onClick={() => {
                setShow("photo");
                setAlbum("");
                setViewFile("");
                setCategory("");
                document.querySelector(".photo").classList.add("active");
                document.querySelector(".video").classList.remove("active");
                document.querySelector(".audio").classList.remove("active");
                document.querySelector(".table").classList.remove("active");
              }}
            >
              <i className="fa fa-photo"></i>
            </li>
            <li
              className="video"
              onClick={() => {
                setShow("video");
                setAlbum("");
                setViewFile("");
                setCategory("");
                document.querySelector(".photo").classList.remove("active");
                document.querySelector(".video").classList.add("active");
                document.querySelector(".audio").classList.remove("active");
                document.querySelector(".table").classList.remove("active");
              }}
            >
              <i className="fa fa-film"></i>
            </li>
            <li
              className="audio"
              onClick={() => {
                setShow("audio");
                setAlbum("");
                setViewFile("");
                setCategory("");
                document.querySelector(".photo").classList.remove("active");
                document.querySelector(".video").classList.remove("active");
                document.querySelector(".audio").classList.add("active");
                document.querySelector(".table").classList.remove("active");
              }}
            >
              <i className="fa fa-headphones"></i>
            </li>
            {localStorage.getItem("dsp-user") ? (
              <li
                className="table"
                onClick={() => {
                  setShow("saved");
                  setAlbum("");
                  setViewFile("");
                  setCategory("");
                  document.querySelector(".photo").classList.remove("active");
                  document.querySelector(".video").classList.remove("active");
                  document.querySelector(".audio").classList.remove("active");
                  document.querySelector(".table").classList.add("active");
                }}
              >
                <i className="fa fa-bookmark"></i>
              </li>
            ) : null}
          </ul>
        </div>
        {viewFile !== "" ? (
          <ViewMedia
            server={server}
            icon={Icon2}
            saved={saved}
            setSaved={setSaved}
            view={viewFile}
            setViewFile={setViewFile}
            setCategory={setCategory}
            category={category}
            setmode={setmode}
            setError={setError}
            setErrorTime={setErrorTime}
            setErrorActions={setErrorActions}
            setErrorMessage={setErrorMessage}
            setErrorTitle={setErrorTitle}
          />
        ) : null}

        {album !== "" ? (
          <ViewAlbumMedia
            icon={Icon}
            album={album}
            setCategory={setCategory}
            show={show}
            setview={setViewFile}
            photos1={photos}
            alpass={Alpass}
          />
        ) : show === "photo" ? (
          <div className="photo-bar">
            {albums
              ? albums.map((album) => {
                return (
                  <div
                    className="photo-card"
                    key={album.id}
                    onClick={() => {
                      if (album === album.fid) {
                        setAlbum("");
                      } else {
                        setAlbum(album.fid);
                        setAlpass(album.pass);
                      }
                    }}
                  >
                    <div className="image">
                      <img src={album.fp} alt="" />
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        ) : show === "video" ? (
          <div className="photo-bar">
            {videos
              ? videos.map((video) => {
                return (
                  <div
                    className="photo-card"
                    key={video.id}
                    onClick={() => {
                      if (album === video.vid) {
                        setViewFile("");
                        setCategory("");
                      } else {
                        setViewFile(video.vid);
                        setCategory("video");
                      }
                    }}
                  >
                    <div className="image">
                      <img src={Icon} alt="" />
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        ) : show === "audio" ? (
          <div className="photo-bar">
            {audios
              ? audios.map((audio) => {
                return (
                  <div
                    className="photo-card"
                    key={audio.id}
                    onClick={() => {
                      if (album === audio.aid) {
                        setViewFile("");
                        setCategory("");
                      } else {
                        setViewFile(audio.aid);
                        setCategory("audio");
                      }
                    }}
                  >
                    <div className="image">
                      <img src={Icon2} alt="" />
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        ) : show === "saved" ? (
          <div className="photo-bar">
            {photos
              ? photos.map((photo) => {
                for (let i = 0; i < saved.length; i++) {
                  if (saved[ i ] === photo.pid) {
                    return (
                      <div
                        className="photo-card"
                        key={photo.id}
                        onClick={() => {
                          setViewFile(photo.pid);
                          setCategory("image");
                        }}
                      >
                        <div className="image">
                          <img src={photo.file ? photo.file : Icon} alt="" />
                        </div>
                      </div>
                    );
                  }
                }
              })
              : null}
            {audios
              ? audios.map((audio) => {
                for (let i = 0; i < saved.length; i++) {
                  if (saved[ i ] === audio.aid) {
                    return (
                      <div
                        className="photo-card"
                        key={audio.id}
                        onClick={() => {
                          if (album === audio.aid) {
                            setViewFile("");
                            setCategory("");
                          } else {
                            setViewFile(audio.aid);
                            setCategory("audio");
                          }
                        }}
                      >
                        <div className="image">
                          <img src={Icon2} alt="" />
                        </div>
                      </div>
                    );
                  }
                }
              })
              : null}
            {videos
              ? videos.map((video) => {
                for (let i = 0; i < saved.length; i++) {
                  if (saved[ i ] === video.vid) {
                    return (
                      <div
                        className="photo-card"
                        key={video.id}
                        onClick={() => {
                          if (album === video.vid) {
                            setViewFile("");
                            setCategory("");
                          } else {
                            setViewFile(video.vid);
                            setCategory("video");
                          }
                        }}
                      >
                        <div className="image">
                          <img src={Icon} alt="" />
                        </div>
                      </div>
                    );
                  }
                }
              })
              : null}
          </div>
        ) : null}

        <div className="body-txt">
          {show === "photo" ? (
            <p>
              At www.dspace.icu, we believe in the power of photography to tell
              stories and preserve memories. Our photo albums are meticulously
              organized to provide an easy and enjoyable browsing experience.
              Take a moment to explore and cherish the moments captured in our
              albums. Thank you for visiting www.dspace.icu Photo Albums. Enjoy the
              journey through our visual stories.
            </p>
          ) : show === "video" ? (
            <p>
              At www.dspace.icu, we believe in the power of videos to tell stories
              and evoke emotions. Our video albums are carefully organized to
              ensure an immersive and enjoyable viewing experience. Take a
              moment to explore and be inspired by the stories captured in
              motion. Thank you for visiting www.dspace.icu Video Albums. Enjoy the
              journey through our visual narratives.
            </p>
          ) : show === "audio" ? (
            <p>
              At www.dspace.icu, we believe in the power of sound to tell stories,
              evoke emotions, and connect people. Our audio albums are
              meticulously organized to provide an enjoyable listening
              experience. Take a moment to explore and indulge in the auditory
              delights we have to offer. Thank you for visiting www.dspace.icu Audio
              Albums. Enjoy the journey through our world of sound.
            </p>
          ) : show === "saved" ? (
            <p>
              We are always keeps your contents private and clean. Save any
              media to use them in future needs.
            </p>
          ) : null}
        </div>
        <div className="tags-bar">
          <ul>
            <li>
              <i className="fa fa-tag"></i>Audios
            </li>
            <li>
              <i className="fa fa-tag"></i>Videos
            </li>
            <li>
              <i className="fa fa-tag"></i>Quotes
            </li>
            <li>
              <i className="fa fa-tag"></i>Graphics
            </li>
            <li>
              <i className="fa fa-tag"></i>Web Templates
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Albums;
