import React, { useEffect, useState } from 'react'
import Footer from '../pages/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';

const GetStart = ({
    server,
    setmode,
    setError,
    setErrorTime,
    setErrorActions,
    setErrorMessage,
    setErrorTitle, }) => {
    const [ time, setTime ] = useState(15);
    const [ b, setB ] = useState(true);

    useEffect(() => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 17);
        // To call defined fuction every second
        let x = setInterval(function () {
            // Getting current time in required format
            let now = new Date().getTime();

            // Calculating the difference
            let t = deadline - now;

            // Getting value of days, hours, minutes, seconds
            let seconds = Math.floor((t % (1000 * 60)) / 1000);

            // Output the remaining time
            setTime(seconds);
            if (seconds < 0) {
                x = 0;
                window.location.href = "/home";
            }
        }, 1000);
    }, [])

    function enableButn() {
        // Converting string to required date format

    }
    return (
        <div className='flow-wall'>
            <div className="scroll-wall">
                <div className="headline">
                    <Link className="back-btn" to={"/home"}>
                        <i className="fa fa-chevron-circle-left"></i>
                    </Link>
                    <div className="textline">
                        <h2>Get Start</h2>
                    </div>
                    <i className="fa fa-play"></i>
                </div>

                <div className="post">
                    <form id='myForm' method='post'>
                        <div className="logo">
                            <img src="DLM.png" alt="" />
                        </div>
                        <h4>Stay Connected Dspace</h4>
                        <p>This program still in development mode. Soon it will appear and all you can be get our best service.</p>
                        <div className="btn-bar">
                            <div className='btn-bx close' onClick={() => {
                                window.location.href = "/home";
                            }}>
                                <i className='fa fa-repeat'></i>
                                {
                                    time < 10 ?
                                        time > 0 ?
                                            "Redirect in 0" + time + " seconds"
                                            :
                                            "Redirecting Now"
                                        :
                                        "Redirect in " + time + " seconds"
                                }</div>

                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default GetStart