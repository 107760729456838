import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Typewriter from "typewriter-effect/dist/core";
import { Link } from "react-router-dom";
import Author from "../assets/author.jpg";
import Img from "../assets/duruthu.png";
import axios from "axios";

function ExploreSpace({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) {
  const [ scr1, setScr1 ] = useState(1);
  const [ begin, setBegin ] = useState(true);
  const [ rates, setRates ] = useState([]);
  const [ news, setNews ] = useState([]);
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    MsgShow(
      "normal",
      "load",
      25,
      "",
      "Please wait while we get our stuff...",
      "Dspace Loading."
    );
    axios.post(server + "/get_10_rates").then((response) => {
      if (response.data.status === "success") {
        setRates(response.data.data);
      }
    });
    axios.post(server + "/get_3_news").then((response) => {
      if (response.data.status === "success") {
        setNews(response.data.data);
        MsgShow(
          "success",
          "success",
          25,
          "ok",
          `Loading Completed Successfully. Enjoy Exploring our space!..`,
          "Loading Complete"
        );
      }
    });
    setBegin(false);
  }, [ begin ]);
  useEffect(() => {
    let btn1 = document.querySelector(".btn1");
    let btn2 = document.querySelector(".btn2");
    let btn3 = document.querySelector(".btn3");
    let btn4 = document.querySelector(".btn4");
    let btn5 = document.querySelector(".btn5");
    let btn6 = document.querySelector(".btn6");
    if (scr1 === 1) {
      btn1.classList.add("act-btn");
      new Typewriter("#t1", {
        strings: [ "What is Dspace?" ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      new Typewriter("#d1", {
        strings: [
          "At www.dspace.icu, we specialize in developing cutting-edge multimedia services to bring your digital dreams to life. From stunning web designs to robust software solutions, our team of experts is dedicated to delivering innovative, high-quality products tailored to meet your unique needs. Join us on this journey to digital excellence.",
        ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
    } else {
      btn1.classList.remove("act-btn");
    }
    if (scr1 === 2) {
      new Typewriter("#t1", {
        strings: [ "What is Our Vision?" ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      new Typewriter("#d1", {
        strings: [
          "To revolutionize the multimedia landscape by delivering unparalleled digital experiences that inspire, engage, and connect people worldwide. We envision a future where our innovative solutions empower individuals and businesses to achieve their fullest potential, breaking barriers and setting new standards in creativity and technology.",
        ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      btn2.classList.add("act-btn");
    } else {
      btn2.classList.remove("act-btn");
    }
    if (scr1 === 3) {
      new Typewriter("#t1", {
        strings: [ "What is Our Mission?" ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      new Typewriter("#d1", {
        strings: [
          "To create exceptional multimedia services that elevate digital experiences for our clients. We are dedicated to innovation, quality, and client satisfaction, providing customized solutions that meet the evolving needs of the digital landscape. Through our commitment to excellence, we aim to empower businesses and individuals to achieve their digital aspirations and make a lasting impact in the world.",
        ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      btn3.classList.add("act-btn");
    } else {
      btn3.classList.remove("act-btn");
    }
    if (scr1 === 4) {
      new Typewriter("#t1", {
        strings: [ "What are the values?" ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      new Typewriter("#d1", {
        strings: [
          "01. Innovation: We constantly push the boundaries of technology and creativity to deliver cutting-edge multimedia solutions",
          "02. Quality: Excellence is at the heart of everything we do. We are committed to providing high-quality products and services.",
          "03. Customer-Centric: Our clients are our top priority. We tailor our solutions to meet their unique needs and exceed their expectations.",
          "04. Integrity: We uphold the highest standards of integrity in all our actions, ensuring transparency and honesty.",
          "05. Collaboration: We believe in the power of teamwork and collaboration, fostering a culture of mutual respect and support.",
          "06. Sustainability: We are dedicated to creating sustainable solutions that positively impact the environment and society.",
          "07. Innovation: We are constantly exploring new ideas and technologies to stay ahead in the ever-evolving digital landscape.",
        ],
        autoStart: true,
        delay: ".1s",
        deleteSpeed: ".1s",
        pauseFor: 5000,
        loop: false,
      });
      btn4.classList.add("act-btn");
    } else {
      btn4.classList.remove("act-btn");
    }
    if (scr1 === 5) {
      new Typewriter("#t1", {
        strings: [ "What is Our worth?" ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      new Typewriter("#d1", {
        strings: [
          "01. Content Quality: High-quality, engaging content that resonates with your target audience is key.",
          "02. User Experience (UX): A smooth, intuitive, and visually appealing user interface can significantly enhance value.",
          "03. SEO and Online Presence: A strong SEO strategy and a good online presence can drive more traffic to your site.",
          "04. Unique Value Proposition: What unique service or product does your site offer that sets it apart from competitors?",
          "05. Revenue Potential: Whether through direct sales, advertising, or other monetization strategies, potential revenue is a major factor.",
          "06. Brand Reputation: Strong branding and a good reputation can elevate your site's value.",
          "07. Technology and Scalability: Using cutting-edge technology and having the capability to scale as your user base grows.",
        ],
        autoStart: true,
        delay: ".1s",
        deleteSpeed: ".1s",
        pauseFor: 5000,
        loop: false,
      });
      btn5.classList.add("act-btn");
    } else {
      btn5.classList.remove("act-btn");
    }
    if (scr1 === 6) {
      new Typewriter("#t1", {
        strings: [ "What would you do?" ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      new Typewriter("#d1", {
        strings: [
          "At www.dspace.icu, we specialize in developing cutting-edge multimedia services to bring your digital dreams to life. From stunning web designs to robust software solutions, our team of experts is dedicated to delivering innovative, high-quality products tailored to meet your unique needs. Join us on this journey to digital excellence.",
        ],
        autoStart: true,
        delay: ".1s",
        pauseFor: 35000,
        loop: false,
      });
      btn6.classList.add("act-btn");
    } else {
      btn6.classList.remove("act-btn");
    }
  }, [ scr1 ]);

  return (
    <div className="wall">
      <div className="scroll-wall">
        <div className="post serve_home">
          <div className="logo">
            <img src="DLM.png" alt="" />
          </div>
          <h4 id="t1"></h4>
          <p id="d1"></p>
          <div className="btn-bar-cir">
            <div
              className="cir-btn btn1"
              onClick={() => {
                setScr1(1);
              }}
            >
              <i className="fa fa-dice-one"></i>
            </div>
            <div
              className="cir-btn btn2"
              onClick={() => {
                setScr1(2);
              }}
            >
              <i className="fa fa-dice-two"></i>
            </div>
            <div
              className="cir-btn btn3"
              onClick={() => {
                setScr1(3);
              }}
            >
              <i className="fa fa-dice-three"></i>
            </div>
            <div
              className="cir-btn btn4"
              onClick={() => {
                setScr1(4);
              }}
            >
              <i className="fa fa-dice-four"></i>
            </div>
            <div
              className="cir-btn btn5"
              onClick={() => {
                setScr1(5);
              }}
            >
              <i className="fa fa-dice-five"></i>
            </div>
            <div
              className="cir-btn btn6"
              onClick={() => {
                setScr1(6);
              }}
            >
              <i className="fa fa-dice-six"></i>
            </div>
          </div>
        </div>
        <div className="post">
          <h4>Our latest news</h4>
          <p>
            Stay connected with the pulse of the world through www.dspace.icu News,
            your trusted source for timely and accurate news updates. Our
            commitment is to deliver the most current and comprehensive news
            coverage, keeping you informed and engaged.
            <br />
            <Link to={"/news_area"} className="see_opt">
              See More <i className="fa fa-chevron-right"></i>
            </Link>
          </p>

          <div className="grid-bar center-bar">
            {news.map((newsItem) => {
              return (
                <Link to={"/news_area"} key={newsItem.id} className="see_opt">
                  <div className="news_card dnews">
                    <div className="news_img">
                      <img
                        src={newsItem.covr.length > 0 ? newsItem.covr : Img}
                        alt=""
                      />
                    </div>
                    <div className="news_text">
                      <div className="hd">
                        <h4>{newsItem.title}</h4>
                      </div>
                      <p>
                        {newsItem.text.substring(0, 150)}...
                        <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="post">
          <h4>Our Ratings</h4>
          <p>
            We hope you're enjoying your experience on our website. Your
            feedback is incredibly important to us and helps us to continually
            improve and offer the best service possible. <br />
            <Link to={"/rate"} className="see_opt">
              <i className="fa fa-star"></i>
              &nbsp;Rate us&nbsp;
              <i className="fa fa-star"></i>
            </Link>
          </p>
          <div className="owl-carousel rates-bar">
            <div className="flex">
              {rates.map((rate) => {
                return (
                  <div className="r-card item" key={rate.id}>
                    <div className="logo">
                      <img src={rate.photo} alt="" />
                    </div>
                    <div className="name">
                      <h4>{rate.user}</h4>
                      {rate.rate < 5 ? (
                        rate.rate < 4 ? (
                          rate.rate < 3 ? (
                            rate.rate < 2 ? (
                              <p>
                                <i
                                  id={rate.id + "r1"}
                                  className="fa fa-star glow"
                                ></i>
                                <i
                                  id={rate.id + "r2"}
                                  className="fa fa-star dark"
                                ></i>
                                <i
                                  id={rate.id + "r3"}
                                  className="fa fa-star dark"
                                ></i>
                                <i
                                  id={rate.id + "r4"}
                                  className="fa fa-star dark"
                                ></i>
                                <i
                                  id={rate.id + "r5"}
                                  className="fa fa-star dark"
                                ></i>
                              </p>
                            ) : (
                              <p>
                                <i
                                  id={rate.id + "r1"}
                                  className="fa fa-star glow"
                                ></i>
                                <i
                                  id={rate.id + "r2"}
                                  className="fa fa-star glow"
                                ></i>
                                <i
                                  id={rate.id + "r3"}
                                  className="fa fa-star dark"
                                ></i>
                                <i
                                  id={rate.id + "r4"}
                                  className="fa fa-star dark"
                                ></i>
                                <i
                                  id={rate.id + "r5"}
                                  className="fa fa-star dark"
                                ></i>
                              </p>
                            )
                          ) : (
                            <p>
                              <i
                                id={rate.id + "r1"}
                                className="fa fa-star glow"
                              ></i>
                              <i
                                id={rate.id + "r2"}
                                className="fa fa-star glow"
                              ></i>
                              <i
                                id={rate.id + "r3"}
                                className="fa fa-star glow"
                              ></i>
                              <i
                                id={rate.id + "r4"}
                                className="fa fa-star dark"
                              ></i>
                              <i
                                id={rate.id + "r5"}
                                className="fa fa-star dark"
                              ></i>
                            </p>
                          )
                        ) : (
                          <p>
                            <i
                              id={rate.id + "r1"}
                              className="fa fa-star glow"
                            ></i>
                            <i
                              id={rate.id + "r2"}
                              className="fa fa-star glow"
                            ></i>
                            <i
                              id={rate.id + "r3"}
                              className="fa fa-star glow"
                            ></i>
                            <i
                              id={rate.id + "r4"}
                              className="fa fa-star glow"
                            ></i>
                            <i
                              id={rate.id + "r5"}
                              className="fa fa-star dark"
                            ></i>
                          </p>
                        )
                      ) : (
                        <p>
                          <i
                            id={rate.id + "r1"}
                            className="fa fa-star glow"
                          ></i>
                          <i
                            id={rate.id + "r2"}
                            className="fa fa-star glow"
                          ></i>
                          <i
                            id={rate.id + "r3"}
                            className="fa fa-star glow"
                          ></i>
                          <i
                            id={rate.id + "r4"}
                            className="fa fa-star glow"
                          ></i>
                          <i
                            id={rate.id + "r5"}
                            className="fa fa-star glow"
                          ></i>
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="post">
          <h4>About Dspace</h4>
          <div className="logo cir">
            <img src={Author} alt="Author" />
          </div>

          <p>
            I created Dark Leo & Dspace for give my knowledge and take your
            knowledge about some various facts.
          </p>
          <p>
            <span className="bl-cut">Designer : </span>Ven. Vithikuliye
            Dhammarathana
          </p>
          <p>
            <span className="bl-cut">Author : </span>Ven. Vithikuliye
            Dhammarathana
          </p>
          <p>
            <span className="bl-cut">Copyrights : </span>&copy;2024 Dark Leo
          </p>
          <p>
            <span className="bl-cut">Version : </span>9.0.0.1
          </p>
          <div className="auth-con">
            <a href="tel:+94768463836">
              <i className="fa fa-phone"></i>
            </a>
            <a href="mailto:admin@dspace.icu">
              <i className="fa fa-envelope"></i>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100093350935383">
              <i className="fa fa-facebook-square"></i>
            </a>
            <a href="https://www.youtube.com/@thuparamavihara">
              <i className="fa fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default ExploreSpace;
