import React from "react";
import "../styles/body.css";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="wall">
      <div className="post">
        <div className="logo">
          <img src="DLM.png" alt="Logo" />
        </div>
        <h4 name="title">Welcome to dspace.icu</h4>
        <p name="details">
          At www.dspace.icu, we specialize in developing cutting-edge multimedia
          services to bring your digital dreams to life. From stunning web
          designs to robust software solutions, our team of experts is dedicated
          to delivering innovative, high-quality products tailored to meet your
          unique needs. Join us on this journey to digital excellence.
        </p>
        <div className="btn-bar">
          <Link className="btn-bx" to={"/explore"}>
            <i className="fa fa-rocket"></i> Explore
          </Link>
          <Link className="btn-bx" to={"/get_start"}>
            <i className="fa fa-chevron-right"></i> Get Started
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Home;
