import React, { useEffect, useState } from "react";
import Icon from "../assets/icon.png";
import axios from "axios";

const DownloadProduct = ({
  view,
  setViewItem,
  pur,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  products,
}) => {
  const [ Item, setItem ] = useState([]);
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    setItem(products.filter((p) => {
      return p.id === view;
    }));
  }, []);
  const DownloadFile = (file, name) => {
    var a = document.createElement("a");
    a.href = file;
    a.click();
  };
  return (
    <div className="event-view">
      {Item
        ? Item.map((file) => {
          return (
            <div className="event-bar" key={file.id}>
              <div className="title">
                <div className="icon">
                  <img src={file.file !== "" ? file.icon : Icon} alt="" />
                </div>
              </div>
              <div className="details">
                <h4>
                  <p>
                    <i className="fa fa-rocket" style={{ marginRight: "10px" }}></i>{file.name}
                  </p>
                </h4>
                <p>
                  <span><i className="fa fa-star" style={{ marginRight: "10px" }}></i> {file.subt}</span>
                </p>

                <p>
                  <span><i className="fa fa-align-left" style={{ marginRight: "10px" }}></i> Details :</span>
                  <br /> {file.deta}
                </p>
                <p>
                  <span><i className="fa fa-hdd" style={{ marginRight: "10px" }}></i> Size :</span> {file.size}
                </p>
                <p>
                  <span><i className="fa fa-desktop" style={{ marginRight: "10px" }}></i> OS Version :</span> {file.osvr}
                </p>
                <p>
                  <span><i className="fa fa-code" style={{ marginRight: "10px" }}></i> Version :</span> {file.prvr}
                </p>
              </div>
              <div className="btn-bar">
                <div
                  className="btn-bx yes"
                  onClick={() => {
                    DownloadFile(file.file, file.name);
                  }}
                >
                  <i className="fa fa-download"></i>
                  get
                </div>
                <div
                  className="btn-bx"
                  onClick={() => {
                    if (pur === "pur") {
                      navigator.clipboard.writeText(
                        "https://www.dspace.icu/purchase_products?id=" +
                        file.id
                      );
                      MsgShow(
                        "success",
                        "success",
                        50,
                        "ok",
                        "Your sharing address is successfully copied. ",
                        "Dspace Share"
                      );
                    } else {
                      navigator.clipboard.writeText(
                        "https://www.dspace.icu/view_products?id=" + file.id
                      );
                      MsgShow(
                        "success",
                        "success",
                        50,
                        "ok",
                        "Your sharing address is successfully copied. ",
                        "Dspace Share"
                      );
                    }
                  }}
                >
                  <i className="fa fa-link"></i>
                  Share
                </div>

                <div
                  className="btn-bx close"
                  onClick={() => {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const product = urlParams.get("id");
                    if (product) {
                      window.history.back();
                      setViewItem("");
                    } else {
                      setViewItem("");
                    }
                  }}
                >
                  <i className="fa fa-close"></i>
                  close
                </div>
              </div>
            </div>
          );
        })
        : null}
    </div>
  );
};

export default DownloadProduct;
