import React, { useEffect, useState } from "react";
import Icon from "../assets/gallery.gif";
import { Link } from "react-router-dom";
import axios from "axios";
import Upload from "../assets/upload.gif";

const ViewAlbumMedia = ({ album, setview, setCategory, photos1, alpass }) => {
  const [ photos, setphotos ] = useState([]);
  const [ pass, setPass ] = useState("");
  const [ passT, setPassT ] = useState("");
  const [ palbum, setPalbum ] = useState([]);
  useEffect(() => {
    if (localStorage.getItem('pass-album')) {
      setPalbum(JSON.parse(localStorage.getItem('pass-album')));
    } else {
      localStorage.setItem('pass-album', JSON.stringify([]));
      window.location.reload();
    }
    if (alpass !== "") {
      setPass(alpass);
      setphotos(photos1.filter((p) => {
        return p.fid === album;
      }))
    } else {
      setphotos(photos1.filter((p) => {
        return p.fid === album;
      }))
    }
  }, []);
  if (localStorage.getItem('pass-album'))
    if (localStorage.getItem('pass-album').includes(pass)) {
      return (
        <div className="photo-bar">
          {photos.map((photo) => {
            return (
              <div
                className="photo-card"
                key={photo.id}
                onClick={() => {
                  setview(photo.pid);
                  setCategory("image");
                }}
              >
                <div className="image">
                  <img src={photo.file ? photo.file : Icon} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="grid-bar center-bar">
          <div className="empty-card">
            <div className="btn-bar">
              <div className="pass-bar">
                <h4>Enter Unlock Code</h4>
                <div className="pass-put">
                  <i className="fa fa-lock"></i>
                  <input
                    type="password"
                    id="name"
                    name="password_for_file"
                    maxLength={16}
                    placeholder="Enter Unlock Code"
                    onChange={(e) => {
                      if (e.target.value.length > 0) {
                        setPassT(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              {pass === passT ? (
                <div
                  className="btn-bx yes"
                  onClick={() => {
                    if (passT === pass) {
                      localStorage.setItem("pass-album", JSON.stringify([ ...palbum, passT ]));
                      setPalbum(JSON.parse(localStorage.getItem('pass-album')));
                    }
                  }}
                >
                  <i className="fa fa-unlock"></i> Unlock
                </div>
              ) : (
                <p className="ppass">Password Not Valid!</p>
              )}
            </div>
          </div>
        </div>
      )
    }
};
export default ViewAlbumMedia;
