import React, { act, useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import AddAlbums from "./AddAlbums";
import AddMedia from "./AddMedia";
import AddNews from "./AddNews";
import AddEvents from "./AddEvents";
import AddProducts from "./AddProducts";
import AddServices from "./AddServices";
import ReplyFAQ from "./ReplyFAQ";
import SonataAct from "./SonataAct";
import Users from "./Users";

const AdminDashboard = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
  cypher,
}) => {
  const [ show, setShow ] = useState("");
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <i
            class="fa fa-dashboard cur-po"
            onClick={() => {
              setShow("");
            }}
          ></i>
          <div class="textline">
            <h2>Admin Dashboard</h2>
          </div>
          <i
            className="fa fa-sign-out cur-po"
            onClick={() => {
              window.history.back();
            }}
          ></i>
        </div>

        <div className="dash-grid">
          {show === "add_albums" ? (
            <AddAlbums
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
              cypher={cypher}
            />
          ) : show === "add_media" ? (
            <AddMedia
              setmode={setmode}
              server={server}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
              cypher={cypher}
            />
          ) : show === "add_news" ? (
            <AddNews
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "add_events" ? (
            <AddEvents
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "add_products" ? (
            <AddProducts
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "add_services" ? (
            <AddServices
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "reply_faqs" ? (
            <ReplyFAQ
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "sonata" ? (
            <SonataAct
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : show === "users" ? (
            <Users
              server={server}
              setmode={setmode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setShow={setShow}
            />
          ) : (
            <div className="btns">
              <div className="grid-btn" onClick={() => setShow("add_media")}>
                <i className="fa fa-images"></i>
                <p>Add Media</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("add_news")}>
                <i className="fa fa-newspaper"></i>
                <p>Add News</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("add_events")}>
                <i className="fa fa-calendar"></i>
                <p>Add Events</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("add_products")}>
                <i className="fa fa-rocket"></i>
                <p>Add Products</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("add_services")}>
                <i className="fa fa-cogs"></i>
                <p>Add Services</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("reply_faqs")}>
                <i className="fas fa-comment"></i>
                <p>Reply FAQs</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("sonata")}>
                <i className="fas fa-key"></i>
                <p>Sonata Logs</p>
              </div>
              <div className="grid-btn" onClick={() => setShow("users")}>
                <i className="fas fa-user"></i>
                <p>Dspace Users</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminDashboard;
