import "./styles/App.css";
import "./styles/View.css";
import "./styles/Plane.css";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Events from "./pages/Events";
import Media from "./pages/Media";
import Profile from "./pages/Profile";
import { Link, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ViewProducts from "./subpages/ViewProducts";
import PurchaseProducts from "./subpages/PurchaseProducts";
import Albums from "./subpages/Albums";
import DownloadMedia from "./subpages/DownloadMedia";
import ViewSuccess from "./subpages/ViewSuccess";
import ErrorProvider from "./pages/ErrorProvider";
import ViewEvents from "./subpages/ViewEvents";
import Background from "./subpages/Background";
import ExploreSpace from "./subpages/ExploreSpace";
import SecuritySupport from "./subpages/SecuritySupport";
import NewsPanel from "./subpages/NewsPanel";
import ReadNews from "./subpages/ReadNews";
import ForgotPass from "./pages/ForgotPass";
import QRshare from "./subpages/QRshare";
import Ratings from "./subpages/Ratings";
import AdminDashboard from "./admin/AdminDashboard";
import Varify from "./pages/Varify";
import DownloadZip from "./subpages/DownloadZip";
import axios from "axios";
import Sonata from "./subpages/Sonata";
import VarifyKey from "./subpages/VarifyKey";
import ForgotAdminPass from "./pages/ForgotAdminPass";
import Notifications from "./subpages/Notifications";
import GetStart from "./subpages/GetStart";
import Icon from "./assets/scan.gif";
import Welcome from "./pages/Welcome";

function App() {
  const [ Error, setError ] = useState("");
  const [ ErrorTitle, setErrorTitle ] = useState("Dspace");
  const [ ErrorTime, setErrorTime ] = useState(5000);
  const [ ErrorActions, setErrorActions ] = useState("none");
  const [ ErrorAction, setErrorAction ] = useState("");
  const [ ErrorMode, setErrorMode ] = useState("");
  const [ ErrorMessage, setErrorMessage ] = useState("Processing Data...");
  const [ share, setShare ] = useState("");
  const [ from, setFrom ] = useState("");
  const [ login, setLogin ] = useState(false);
  const [ Notification, setNotification ] = useState(false);
  const [ userData, setUserData ] = useState([]);
  const [ nots, setNots ] = useState([]);
  const server_url = "https://app.dspace.icu";
  //const server_url = "http://localhost:3000";
  const secretKey = "iamtoshr";
  const MsgShow = (mode, error, time, actions, message, title) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setErrorMode(mode);
  };

  var user = JSON.parse(localStorage.getItem("dsp-user"));
  useEffect(() => {
    if (localStorage.getItem("dsp-login-status")) {
      setLogin(localStorage.getItem("dsp-login-status"));
      if (localStorage.getItem("login")) {
      } else {
        localStorage.setItem("login", "signin");
        window.location.href = "/home";
      }
    } else {
      localStorage.setItem("dsp-login-status", false);
      localStorage.setItem("dsp-theme", false);
      localStorage.setItem("login", "signin");
      window.location.reload();
    }
    if (localStorage.getItem("mem-user")) {
      if (localStorage.getItem("mem-pass")) {
        //code goes here for sign in

        //stringify data
        var data = {
          user: localStorage.getItem("mem-user"),
          pass: localStorage.getItem("mem-pass"),
        };
        axios.post(server_url + "/signin", data).then((response) => {
          // handle success
          if (response.data.status === "success") {

            setUserData(response.data.data);
            localStorage.setItem("dsp-login-status", "true");
            localStorage.setItem(
              "dsp-user",
              JSON.stringify(response.data.data[ 0 ])
            );
            localStorage.setItem(
              "for-email",
              JSON.stringify(response.data.data[ 0 ].email)
            );
            setLogin("true");
          } else {
            MsgShow(
              "error",
              "warning",
              50,
              "close",
              response.data.msg,
              "Ooops..."
            );
          }
        });
      }
    }
    if (localStorage.getItem("dsp-theme") === "true") {
      document.getElementById("theme").click();
    }
  }, []);
  const [ state, setState ] = useState("home");
  useEffect(() => {
    var ho = document.getElementById("home_r");
    var pr = document.getElementById("pro_r");
    var me = document.getElementById("med_r");
    var ev = document.getElementById("eve_r");
    var pro = document.getElementById("prof_r");

    if (state === "home") {
      ho.classList.remove("fa-gradient");
      ho.classList.add("fa-gradient-active");
    } else {
      ho.classList.remove("fa-gradient-active");
      ho.classList.add("fa-gradient");
    }
    if (state === "products") {
      pr.classList.remove("fa-gradient");
      pr.classList.add("fa-gradient-active");
    } else {
      pr.classList.remove("fa-gradient-active");
      pr.classList.add("fa-gradient");
    }
    if (state === "media") {
      me.classList.remove("fa-gradient");
      me.classList.add("fa-gradient-active");
    } else {
      me.classList.remove("fa-gradient-active");
      me.classList.add("fa-gradient");
    }
    if (state === "events") {
      ev.classList.remove("fa-gradient");
      ev.classList.add("fa-gradient-active");
    } else {
      ev.classList.remove("fa-gradient-active");
      ev.classList.add("fa-gradient");
    }
    if (state === "profile") {
      if (localStorage.getItem("dsp-user")) {

      } else {

        pro.classList.remove("fa-gradient");
        pro.classList.add("fa-gradient-active");
      }
    } else {
      if (localStorage.getItem("dsp-user")) {

      } else {

        pro.classList.remove("fa-gradient-active");
        pro.classList.add("fa-gradient");
      }

    }


  }, [ state ]);
  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to={"/home"} onClick={() => { setState("home"); }}>
              <i id="home_r" className="fa fa-home fa-gradient"></i>
            </Link>
          </li>
          <li>
            <Link to={"/products"} onClick={() => { setState("products"); }}>
              <i id="pro_r" className="fa fa-rocket fa-gradient"></i>
            </Link>
          </li>
          <li>
            <Link to={"/media"} onClick={() => { setState("media"); }}>
              <i id="med_r" className="fa fa-images fa-gradient"></i>
            </Link>
          </li>

          <li>
            <Link to={"/events"} onClick={() => { setState("events"); }}>
              <i id="eve_r" className="fa fa-trophy fa-gradient"></i>
            </Link>
          </li>
          <li>
            <Link to={"/profile"} onClick={() => { setState("profile"); }}>
              {login !== "true" ? (
                <i id="prof_r" className="fa fa-user-circle fa-gradient"></i>
              ) : (
                <div className="log-cont">
                  <div className="log-photo">
                    <img src={localStorage.getItem("dsp-user") ? user.photo : Icon} alt="user" />
                  </div>
                </div>
              )}
            </Link>
          </li>
        </ul>
      </nav>
      {
        localStorage.getItem("dsp-user") ?
          <div id="myDiv" className={Notification ? "noti-btn-active" : "noti-btn"} onClick={() => {
            if (Notification) {
              setNotification(false);
            } else {
              setNotification(true);
            }
          }}>
            <i className="fa fa-bell"></i>
          </div>
          :
          null
      }
      {Error.length > 0 ? (
        <ErrorProvider
          error={Error}
          setError={setError}
          time={ErrorTime}
          title={ErrorTitle}
          actions={ErrorActions}
          setErrorTime={setErrorTime}
          setErrorAction={setErrorAction}
          message={ErrorMessage}
          mode={ErrorMode}
          setmode={setErrorMode}
        />
      ) : null}
      <Welcome />
      <Background />
      {share !== "" ? (
        <QRshare
          server={server_url}
          addr={share}
          setShare={setShare}
          from={from}
        />
      ) : null}
      <Routes>
        <Route path="/" element={<Home server={server_url} />} />
        <Route
          path="/varify"
          element={
            <Varify
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route path="/home" element={<Home server={server_url} />} />
        <Route path="/products" element={<Products server={server_url} />} />
        <Route
          path="/rate"
          element={
            <Ratings
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route path="/media" element={<Media server={server_url} />} />
        <Route
          path="/profile"
          element={
            <Profile
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              setLogin={setLogin}
              login={login}
              setUserData={setUserData}
              userData={userData}
              setNots={setNots}
            />
          }
        />
        <Route path="/events" element={<Events server={server_url} />} />
        <Route
          path="/view_products"
          element={
            <ViewProducts
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setShare={setShare}
              setFrom={setFrom}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/terms_n_conditions"
          element={
            <SecuritySupport
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
            />
          }
        />
        <Route
          path="/varify_key"
          element={
            <VarifyKey
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
            />
          }
        />
        <Route
          path="/services"
          element={
            <PurchaseProducts
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setShare={setShare}
              setFrom={setFrom}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/get_start"
          element={
            <GetStart
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setShare={setShare}
              setFrom={setFrom}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/download_zip"
          element={
            <DownloadZip
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route
          path="/view_albums"
          element={
            <Albums
              server={server_url}
              setShare={setShare}
              setFrom={setFrom}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
              cypher={secretKey}
            />
          }
        />
        <Route
          path="/forgot_password"
          element={
            <ForgotPass
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route
          path="/forgot_admin_password"
          element={
            <ForgotAdminPass
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
            />
          }
        />
        <Route
          path="/download_media"
          element={
            <DownloadMedia
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/view_events"
          element={
            <ViewEvents
              setShare={setShare}
              server={server_url}
              setFrom={setFrom}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route path="/news_area" element={<NewsPanel server={server_url} />} />
        <Route
          path="/sonata"
          element={
            <Sonata
              setmode={setErrorMode}
              server={server_url}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/admin_dashboard"
          element={
            <AdminDashboard
              cypher={secretKey}
              setmode={setErrorMode}
              server={server_url}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route
          path="/read_news"
          element={
            <ReadNews
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
        <Route path="/explore" element={<ExploreSpace server={server_url}
          setmode={setErrorMode}
          setError={setError}
          setErrorTime={setErrorTime}
          setErrorActions={setErrorActions}
          setErrorMessage={setErrorMessage}
          setErrorTitle={setErrorTitle}
          act={ErrorAction}
          setAction={setErrorAction} />} />

        <Route
          path="/view_success"
          element={
            <ViewSuccess
              server={server_url}
              setmode={setErrorMode}
              setError={setError}
              setErrorTime={setErrorTime}
              setErrorActions={setErrorActions}
              setErrorMessage={setErrorMessage}
              setErrorTitle={setErrorTitle}
              act={ErrorAction}
              setAction={setErrorAction}
            />
          }
        />
      </Routes>
      {
        localStorage.getItem("dsp-user") ?
          <Notifications
            setNot={setNotification}
            setNots={setNots}
            noti={Notification}
            nots={nots}
            server={server_url}
            setmode={setErrorMode}
            setError={setError}
            setErrorTime={setErrorTime}
            setErrorActions={setErrorActions}
            setErrorMessage={setErrorMessage}
            setErrorTitle={setErrorTitle}
          />
          :
          null
      }
    </>
  );
}

export default App;
