import axios from "axios";
import React, { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";

const AddAlbums = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
  cypher,
}) => {
  const [ Albums, setAlbums ] = useState([]);
  const [ begin, setBegin ] = useState(true);
  const [ alname, setAlname ] = useState("");
  const [ alpass, setAlpass ] = useState("");
  const [ file, setFile ] = useState([]);
  const [ fp, setFp ] = useState("");
  const [ fid, setFid ] = useState(uuidV4());
  const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
  };
  useEffect(() => {
    axios.post(server + "/get_all_albums").then((response) => {
      if (response.data.status === "success") {
        const res = decrypt(cypher, response.data.data);
        const conv = JSON.parse(res);
        setAlbums(conv);
      }
    });
    setBegin(false);
  }, [ begin ]);
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return handleFileChange(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  const handleFileChange = (e) => {
    setFp(e);
  };
  function handleUpload() {
    var data = {
      alname: alname,
      fp: fp,
      fid: fid,
      alpass: alpass,
    };
    axios.post(server + "/add_album", data).then((response) => {
      if (response.data.status === "success") {
        clearF();
        MsgShow(
          "success",
          "success",
          50,
          "ok",
          "Your album was added to Dspace Albums Data. Please make changes further if you want.",
          "Album Added"
        );
        setFid(uuidV4());
      } else {
        MsgShow(
          "error",
          "warning",
          50,
          "close",
          `${response.data.msg}`,
          "Profile Photo Updated"
        );
      }
    });
  }
  function clearF() {
    let form = document.getElementById("formc");
    form.reset();
    setAlname("");
    setAlpass("");
    setFile([]);
    setFp("");
    setBegin(true);
  }
  return (
    <>
      <div className="photo-bar">
        {Albums
          ? Albums.map((album) => {
            return (
              <div className="photo-card" key={album.id}>
                <div className="image">
                  <img src={album.fp} alt="" />
                </div>
              </div>
            );
          })
          : null}
      </div>
      <form action="" method="post" id="formc">
        <h4>Add Albums</h4>

        <div className="flex-bar">
          <div className="input">
            <i className="fa fa-image"></i>
            <input
              type="text"
              name="album"
              id="name"
              value={file ? file.name : "Choose A Cover"}
              onClick={() => {
                document.getElementById("upload").click();
              }}
              placeholder="Choose A File"
            />
          </div>
        </div>
        <div className="flex-bar">
          <div className="input">
            <i className="fa fa-image"></i>
            <input type="text" name="album" id="name" value={fid} />
          </div>
        </div>
        <div className="flex-bar">
          <div className="input">
            <i className="fa fa-lock"></i>
            <input
              type="password"
              name="password"
              id="password"
              maxLength={8}
              placeholder="Set Password"
              onChange={(e) => {
                setAlpass(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex-bar">
          <div className="input">
            <i className="fa fa-folder"></i>
            <input
              type="text"
              name="album"
              id="name"
              placeholder="Album Name"
              onChange={(e) => {
                setAlname(e.target.value);
              }}
            />
          </div>
        </div>
        <input
          type="file"
          name="upload"
          id="upload"
          onChange={(e) => {
            setFile(e.target.files[ 0 ]);
            getBase64(e.target.files[ 0 ]);
          }}
        />
        <div className="btn-bar">
          <div
            className="btn-bx"
            onClick={() => {
              handleUpload();
            }}
          >
            <i className="fa fa-plus"></i>
            Insert
          </div>
          <div
            className="btn-bx close"
            onClick={() => {
              clearF();
              setShow("");
            }}
          >
            <i className="fa fa-close"></i>
            Close
          </div>
        </div>
      </form>
    </>
  );
};

export default AddAlbums;
