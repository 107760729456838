import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../pages/Footer";
import Img from "../assets/duruthu.png";
import axios from "axios";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

const ReadNews = ({
  server,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  act,
}) => {
  const [ news, setNews ] = useState([]);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("id");
  const views = urlParams.get("views");

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };

  useEffect(() => {
    if (product) {
      const data = {
        id: product,
        views: views,
      }
      axios
        .post(server + "/get_news_data", data)
        .then((response) => {
          if (response.data.status === "success") {
            setNews(response.data.data);
            MsgShow(
              "success",
              "success",
              5000,
              "ok",
              "We have found the news that you looking for. You can scroll down to read the article.",
              "News Found"
            );
          }
        });
    }
  }, []);

  const LikePost = (val, likes) => {
    const data = { id: val, likes: likes };
    axios.post(server + "/like_news", data).then((response) => {
      if (response.data.status === "success") {
        MsgShow(
          "success",
          "success",
          5000,
          "ok",
          "You have liked the article.",
          "Liked Post"
        );
      }
    });
  };
  const UnlikePost = (val, hates) => {
    const data = { id: val, hates: hates };
    axios.post(server + "/unlike_news", data).then((response) => {
      if (response.data.status === "success") {
        MsgShow(
          "success",
          "success",
          5000,
          "ok",
          "You have Unliked the article.",
          "Unliked Post"
        );
      }
    });
  };
  const SharePost = (val) => {
    navigator.clipboard.writeText(window.location.href);
    MsgShow(
      "success",
      "success",
      50,
      "ok",
      "Your sharing address is successfully copied. ",
      "Dspace Share"
    );
  };

  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div className="headline">
          <Link className="back-btn" to={"/news_area"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div className="textline">
            <h2>Article</h2>
          </div>

          <i className="fa fa-newspaper"></i>
        </div>
        <div className="news-wall">
          {news.map((item) => {
            return (
              <div key={item.id}>
                <div className="news-img">
                  <img src={item.covr} alt="" />
                </div>
                <div className="news-title">
                  <h4>{item.title}</h4>
                </div>
                <div className="news-text">
                  <p>{item.text}</p>
                </div>

                <div className="line-bar">
                  <div className="line-back"></div>
                  <div
                    className="line"
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    <div
                      className="btn-wrap"
                      onClick={() => {
                        var likes = item.love + 1;
                        LikePost(item.id, likes);
                      }}
                    >
                      <div className="btn yes">
                        <i className="fa fa-heart"></i>
                      </div>
                    </div>

                    <div
                      className="btn-wrap"
                      onClick={() => {
                        var hates = item.hate + 1;
                        UnlikePost(item.id, hates);
                      }}
                    >
                      <div className="btn no">
                        <i className="fa fa-heart-broken"></i>
                      </div>
                    </div>
                    <div
                      className="btn-wrap"
                      onClick={() => {
                        SharePost(item.id);
                      }}
                    >
                      <div className="btn">
                        <i className="fa fa-link "></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="tags-bar">
          <ul>
            <li>
              <i className="fa fa-tag"></i>Audios
            </li>
            <li>
              <i className="fa fa-tag"></i>Videos
            </li>
            <li>
              <i className="fa fa-tag"></i>Quotes
            </li>
            <li>
              <i className="fa fa-tag"></i>Graphics
            </li>
            <li>
              <i className="fa fa-tag"></i>Web Templates
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ReadNews;
