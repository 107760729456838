import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import DownloadProduct from "./DownloadProduct";
import axios from "axios";
import ViewService from "./ViewService";

const PurchaseProducts = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  const [ products, setProducts ] = useState([]);
  const [ purItem, setPurItem ] = useState("");
  const [ search, setSearch ] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("id");
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };

  useEffect(() => {
    MsgShow(
      "normal",
      "load",
      25,
      "",
      "Please wait while we get our stuff...",
      "Dspace Services"
    );
    axios.post(server + "/get_services").then((response) => {
      if (response.data.status === "success") {
        setProducts(response.data.data);
        setTimeout(() => {
          MsgShow(
            "success",
            "success",
            25,
            "ok",
            `We found ${response.data.data.length < 10 ? "0" + response.data.data.length : response.data.data.length} service(s) for you. Enjoy!..`,
            "Dspace Services"
          );

        }, 2000);
        return;
      } else {
        MsgShow(
          "normal",
          "warning",
          5000,
          "ok",
          response.data.msg,
          "Our Problem"
        );
        return;
      }
    });
  }, []);
  useEffect(() => {
    if (products.length > 0) {
      if (product) {
        var vg = document.getElementById(product + "kvr");
        if (isNaN(vg)) {
          vg.click();
        }
      }
    }
  }, [ products ])
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div className="headline">
          <Link className="back-btn" to={"/products"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div className="textline">
            <h2>Our Services</h2>
          </div>
          <i className="fa fa-cogs"></i>
        </div>

        {purItem !== "" ? (
          <ViewService
            pur={"pur"}
            view={purItem}
            setViewItem={setPurItem}
            setmode={setmode}
            setError={setError}
            setErrorTime={setErrorTime}
            setErrorActions={setErrorActions}
            setErrorMessage={setErrorMessage}
            setErrorTitle={setErrorTitle}
            products={products}
            server={server}
          />
        ) : null}
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search Services Here..."
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setSearch(e.target.value);
                } else {
                  setSearch("");
                }
              }}
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        <div className="photo-bar">
          {products.map((product) => {
            if (search.length > 0) {
              var ti = product.title.toLowerCase();
              var tx = product.text.toLowerCase();
              if ((ti + tx).includes(search.toLowerCase())) {
                if (product.ageLmt <= 18) {
                  return (
                    <div
                      className="photo-card"
                      id={product.id + "kvr"}
                      key={product.id}
                      onClick={() => {
                        setPurItem(product.id);
                      }}
                    >
                      <div className="image">
                        <img
                          src={product.icon.length > 0 ? product.icon : "DLM.png"}
                          alt=""
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="photo-card"
                      id={product.id + "kvr"}
                      key={product.id}
                      onClick={() => {
                        setPurItem(product.id);
                      }}
                    >
                      <div className="image">
                        <div className="free-btn">
                          <i className="fa fa-shield"></i>
                        </div>
                        <img
                          src={product.icon.length > 0 ? product.icon : "DLM.png"}
                          alt=""
                        />
                      </div>
                    </div>
                  );
                }
              }
            } else {
              if (product.ageLmt <= 18) {
                return (
                  <div
                    className="photo-card"
                    id={product.id + "kvr"}
                    key={product.id}
                    onClick={() => {
                      setPurItem(product.id);
                    }}
                  >
                    <div className="image">
                      <img
                        src={product.icon.length > 0 ? product.icon : "DLM.png"}
                        alt=""
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="photo-card"
                    id={product.id + "kvr"}
                    key={product.id}
                    onClick={() => {
                      setPurItem(product.id);
                    }}
                  >
                    <div className="image">
                      <div className="free-btn">
                        <i className="fa fa-shield"></i>
                      </div>
                      <img
                        src={product.icon.length > 0 ? product.icon : "DLM.png"}
                        alt=""
                      />
                    </div>
                  </div>
                );
              }

            }
          })}
        </div>
        <div className="body-txt">
          <p>
            Welcome to our Purchase Products section, your destination for
            high-quality items that cater to your every need. Our products are
            meticulously selected to ensure they meet the highest standards of
            quality and value. Books & E-books: Expand your horizons with our
            collection of the latest and greatest reads across various genres
            and topics. Software Solutions: Discover our range of powerful
            software tools designed to boost productivity and creativity.
            Educational Courses: Invest in your future with our expertly crafted
            courses that offer in-depth knowledge and skills. Templates &
            Utilities: Enhance your projects with our premium templates and
            utility tools, tailored to your professional and personal needs.
            Exclusive Accessories: Add a touch of elegance and functionality
            with our selection of unique and stylish accessories. Experience
            seamless shopping with our user-friendly platform, secure payment
            options, and exceptional customer service. Don’t miss out on
            exclusive offers and discounts available only in our purchase
            section.
          </p>
        </div>
        <div className="tags-bar">
          <ul>
            <li>
              <i className="fa fa-tag"></i>News
            </li>
            <li>
              <i className="fa fa-tag"></i>dspace
            </li>
            <li>
              <i className="fa fa-tag"></i>about
            </li>
            <li>
              <i className="fa fa-tag"></i>blog
            </li>
            <li>
              <i className="fa fa-tag"></i>explore
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PurchaseProducts;
