import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Icon from "../assets/DLM.png";
import { Link } from "react-router-dom";
import EventItemDisplay from "./EventItemDisplay";
import axios from "axios";

const ViewEvents = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  const [ events, setEvents ] = useState([]);
  const [ viewItem, setViewItem ] = useState("");
  const [ search, setSearch ] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const event = urlParams.get("id");

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    MsgShow(
      "normal",
      "load",
      25,
      "",
      "We are loading our events. Wait a moment.",
      "Please Wait..."
    );
    axios.post(server + "/get_all_events").then((response) => {
      if (response.data.status === "success") {
        setEvents(response.data.data);
        setTimeout(() => {
          MsgShow(
            "success",
            "success",
            3500,
            "ok",
            `${response.data.data.length < 10 ? "0" + response.data.data.length : response.data.data.length} Events(s) found.`,
            "Loading Completed..."
          );

        }, 2000);
      } else {
        MsgShow(
          "normal",
          "warning",
          5000,
          "ok",
          response.data.msg,
          "Our Problem"
        );
      }
      if (event) {
        setViewItem(event);
      }
      return;
    });
  }, []);

  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div className="headline">
          <Link className="back-btn" to={"/events"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div className="textline">
            <h2>Events</h2>
          </div>
          <i className="fa fa-calendar"></i>
        </div>
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Search Events Here..."
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setSearch(e.target.value);
                } else {
                  setSearch("");
                }
              }}
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        {viewItem !== "" ? (
          <EventItemDisplay
            view={viewItem}
            setViewItem={setViewItem}
            setmode={setmode}
            setError={setError}
            setErrorTime={setErrorTime}
            setErrorActions={setErrorActions}
            setErrorMessage={setErrorMessage}
            setErrorTitle={setErrorTitle}
            events={events}
          />
        ) : (
          <div className="photo-bar">
            {events.length > 0
              ? events.map((event) => {
                if (search.length > 0) {
                  var ti = event.title.toLowerCase();
                  var tx = event.deta.toLowerCase();
                  if ((ti + tx).includes(search.toLowerCase())) {
                    return (
                      <div
                        className="photo-card"
                        key={event.id}
                        onClick={() => {
                          setViewItem(event.id);
                        }}
                      >
                        <div className="image">
                          <img src={event.pic} alt="" />

                        </div>
                      </div>
                    );

                  }
                } else {
                  return (
                    <div
                      className="photo-card"
                      key={event.id}
                      onClick={() => {
                        setViewItem(event.id);
                      }}
                    >
                      <div className="image">
                        <img src={event.pic} alt="" />

                      </div>
                    </div>
                  );
                }
              })
              : null}
          </div>
        )}

        <div className="body-txt">
          <p>
            Our Events Wall is your gateway to staying connected and engaged
            with everything happening at www.dspace.icu. Whether you're looking to
            attend, revisit, or simply stay informed, our Events Wall is the
            place to be. Thank you for joining us at www.dspace.icu Events Wall. Enjoy
            exploring and staying connected!
          </p>
        </div>
        <div className="tags-bar">
          <ul>
            <li>
              <i className="fa fa-tag"></i>Audios
            </li>
            <li>
              <i className="fa fa-tag"></i>Videos
            </li>
            <li>
              <i className="fa fa-tag"></i>Quotes
            </li>
            <li>
              <i className="fa fa-tag"></i>Graphics
            </li>
            <li>
              <i className="fa fa-tag"></i>Web Templates
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ViewEvents;
