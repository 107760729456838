import axios from "axios";
import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import moment from "moment";
import { v4 as uuidV4 } from "uuid";

const SonataAct = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
}) => {
  const [ begin, setBegin ] = useState(true);
  const [ entries, setEntries ] = useState([]);

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.post(server + "/get_m_keys").then((response) => {
      if (response.data.status === "success") {
        if (response.data.data) {
          setEntries(response.data.data);
          MsgShow(
            "success",
            "success",
            50,
            "ok",
            `You have ${response.data.data.length} entries to configure.`,
            "Entries Found."
          );
          return setBegin(false);

        } else {
          setEntries(response.data.data);
          return setBegin(false);
        }
      } else {
        setEntries(response.data.data);
        return setBegin(false);
      }
    });
  }, [ begin ]);
  const GenKey = (mid, email, tr, cyphertext) => {
    const result = Array.from(cyphertext.matchAll(/[A-Z]/g)).map(match => match[ 0 ]).join('');
    const key_set = result.substring(0, 3) + "-" + result.substring(3, 6) + "-" + result.substring(6, 10) + "-" + result.substring(10, 12);
    var keyGenerated = key_set;
    const data = {
      mid: mid,
      key: keyGenerated,
      pay: "payed",
      date: moment(Date.now()).format("YYYY-MM-DD"),
    };
    axios.post(server + "/m_key_enter", data).then((response) => {
      if (response.data.status === "success") {
        emailjs.init({
          publicKey: "00YXxLX6rNDtuAHhT",
        });
        var template_params = {
          mail_to: email,
          code: keyGenerated,
          id: uuidV4(),
          date_granted: moment(Date.now()).format("YYYY-MM-DD"),
          dev_id: mid,
          from: "Sonata Activation",
          tr_num: tr,
        };
        emailjs
          .send("service_o5xumnk", "template_5s57268", template_params)
          .then(
            (response) => {
              MsgShow(
                "success",
                "success",
                50,
                "ok",
                `You have varified and sent the key: ${keyGenerated} to email: ${email}. Transaction: ${tr} has been accepted.`,
                "Key Number Sent"
              );
              return setBegin(true);
            },
            (error) => {
              MsgShow(
                "error",
                "warning",
                5000,
                "close",
                `${error}`,
                "Key Sent Failed"
              );
            }
          );
      }
    });
  };

  const deleteReq = (did) => {
    const data = {
      uid: did,
    };
    axios.post(server + "/req_del_sonata", data).then((response) => {
      if (response.data.status === "success") {
        MsgShow(
          "success",
          "success",
          5000,
          "ok",
          `Request has been deleted successfully. Transaction ID: ${did}`,
          "Request Deleted"
        );
        axios.post(server + "/get_m_keys").then((response) => {
          if (response.data.status === "success") {
            if (response.data.data) {
              setEntries(response.data.data);
            } else {
              setEntries([]);
            }
          }
        });
      }
    });
  };
  return (
    <>
      <div className="table-bar">
        <h4>All Requests.</h4>
        <div className="table-cr">
          {
            entries.map((val) => {
              return (
                <div className="table-row" key={val.id}>
                  <div className="title">
                    <p className="btn-cir-bar">
                      <span
                        className="btn-cir"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          border: "2px dodgerblue solid",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          color: "dodgerblue",
                          fontWeight: "600",
                        }}
                      >
                        {val.id}
                      </span>
                    </p>
                  </div>
                  <div className="details">
                    <span>
                      <i className="fa fa-envelope"></i>
                      {val.email}
                    </span>
                    <br />
                    {val.keynum ? (
                      <span>
                        <i className="fa fa-key"></i>
                        {val.keynum}
                      </span>
                    ) : null}
                    <br />
                    <span>
                      <i className="fa fa-calendar"></i>
                      {val.date}
                    </span>
                    <br />
                    <span>
                      <i className="fa fa-money-bill"></i>
                      {val.pay !== "payed" ? "Not Payed" : "Payed"}
                    </span>
                    <p className="btn-cir-bar">
                      <span
                        className="btn-cir"
                        onClick={() => {
                          GenKey(val.devid, val.email, val.id, val.cypher);
                        }}
                      >
                        <i className="fa fa-key"></i>
                      </span>
                      <span
                        className="btn-cir"
                        onClick={() => {
                          deleteReq(val.devid);
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="btn-bar" onClick={() => {
          setShow("");
          setEntries([]);
        }}>
          <div className="btn-bx close">
            <i className="fa fa-close"></i>
            Close
          </div>
        </div>
      </div>
    </>
  );
};

export default SonataAct;
