import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

const Sonata = ({
  server,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [ mid, setMid ] = useState(urlParams.get("mid"));
  const [ uid, setUid ] = useState("");
  const [ date, setDate ] = useState("");
  const [ tr, setTr ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ cypher, setCypher ] = useState("");
  const [ vdate, setVdate ] = useState("");
  const [ begin, setBegin ] = useState(true);
  const [ ReqSign, setReqSign ] = useState(false);
  const [ key, setKey ] = useState("");
  const [ purchase, setPurchase ] = useState(false);
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  var user = JSON.parse(localStorage.getItem("dsp-user"));
  useEffect(() => {
    const cyphertext = urlParams.get("cypher");
    setCypher(cyphertext);
    localStorage.setItem("m-key", urlParams.get("mid"));
    if (localStorage.getItem("dsp-user")) {
      setUid(user.uid);
      if (urlParams.get("mid")) {
        const data = {
          mid: urlParams.get("mid"),
        };
        axios.post(server + "/m_id", data).then((response) => {
          if (response.data.status === "success") {
            if (response.data.data) {
              setMid(response.data.data[ 0 ].devid);
              setUid(response.data.data[ 0 ].uid);
              setKey(response.data.data[ 0 ].keynum);
              setDate(response.data.data[ 0 ].date);
              setVdate(response.data.data[ 0 ].vdate);
              setEmail(response.data.data[ 0 ].email);
              setTr(response.data.data[ 0 ].id);
              if (response.data.data[ 0 ].pay === "payed") {
                setPurchase(true);
              } else {
                setPurchase(false);
              }
              setBegin(false);
            }
          } else {
            var d = document.getElementById("btnsub");
            MsgShow(
              "success",
              "success",
              50,
              "close",
              `Your program key entry is new to our database. Please wait till we fetch your email and submit for further access.`,
              "New Key Found"
            );
            setTimeout(() => {
              d.click();
            }, 2000);
          }
        });
      }
    } else {
      window.location.href = "/profile";
    }
  }, [ begin ]);

  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div class="headline">
          <Link className="back-btn" to={"/profile"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div class="textline">
            <h2>Sonata Activation</h2>
          </div>
          <i class="fa fa-key"></i>
        </div>
        <div className="post">
          <form id="formc" method="post">
            <div className="logo">
              <img src="DLM.png" alt="" />
            </div>
            {key.length > 0 ? (
              <h4>Your Activation Code</h4>
            ) : tr ? (
              <h4>Your Transaction Remark</h4>
            ) : (
              <h4>Your Program Code</h4>
            )}
            {key.length > 0 ? null : email.length > 0 ? (
              <div className="flex-bar">
                <div className="input">
                  <i className="fa fa-link"></i>
                  <input
                    type="text"
                    name="trns"
                    id="name"
                    value={tr}
                    onClick={() => {
                      window.navigator.clipboard.writeText(tr);
                      MsgShow(
                        "success",
                        "success",
                        50,
                        "close",
                        "Your transaction key has been successfully copied. Use this number when sumbit your payment.",
                        "Transaction Key Copied"
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="flex-bar">

              </div>
            )}
            {key.length > 0 ? (
              <div className="flex-bar">
                <div className="input">
                  <i className="fa fa-key"></i>
                  <input
                    type="text"
                    name="uid"
                    id="name"
                    value={key}
                    onClick={() => {
                      window.navigator.clipboard.writeText(key);
                      MsgShow(
                        "success",
                        "success",
                        50,
                        "close",
                        "Your key has been successfully copied. Paste copied key @ Sonata app.",
                        "Key Number Copied"
                      );
                    }}
                  />
                </div>
              </div>
            ) : email.length > 0 ? null : (
              <div className="flex-bar">
                <div className="input">
                  <i className="fa fa-key"></i>
                  <input type="text" name="uid" id="name" value={mid} />
                </div>
              </div>
            )}
            {tr > 0 ?
              <p>
                {purchase === false ? (
                  <span>
                    <i className="fa fa-money-bill"></i> &nbsp; Payment Not
                    Varified Yet
                  </span>
                ) : (
                  <span style={{ color: "limegreen" }}>
                    <i className="fa fa-check-circle"></i> &nbsp; Payment Varified
                    : {vdate}
                  </span>
                )}
              </p>
              :
              <p>
                <span>
                  <i className="fa fa-ticket"></i> &nbsp; Submit device id to get the Transaction Remark.
                </span>
              </p>
            }
            <div className="btn-bar">
              {
                tr > 0 ?
                  purchase === false ? (
                    <div
                      className="btn-bx"
                      onClick={() => {
                        setBegin(true);
                      }}
                    >
                      <i className="fa fa-ticket"></i>
                      Varify
                    </div>
                  ) : null : null}
              {key.length > 0 ? (
                <div
                  className="btn-bx"
                  onClick={() => {
                    window.navigator.clipboard.writeText(key);
                    MsgShow(
                      "success",
                      "success",
                      50,
                      "close",
                      "Your key has been successfully copied. Paste copied key @ Sonata app.",
                      "Key Number Copied"
                    );
                  }}
                >
                  <i className="fa fa-copy"></i>
                  Copy Key
                </div>
              ) : purchase === false ? (
                email.length > 0 ? null : (
                  <div
                    className="btn-bx yes"
                    id="btnsub"
                    onClick={() => {
                      if (user.email.length > 0) {
                        const data = {
                          mid: mid,
                          email: user.email,
                          cypher: cypher,
                          date: moment(Date.now()).format("YYYY-MM-DD"),
                        };
                        axios
                          .post(server + "/m_email_enter", data)
                          .then((response) => {
                            if (response.data.status === "success") {
                              MsgShow(
                                "success",
                                "success",
                                50,
                                "close",
                                `Your details has been updated on our database. This email will be used to varification process. Please check your inbox.`,
                                "Email Address Updated"
                              );
                              const data = {
                                mid: mid,
                              };
                              axios
                                .post(server + "/m_id", data)
                                .then((response) => {
                                  if (response.data.status === "success") {
                                    if (response.data.data) {
                                      setMid(response.data.data[ 0 ].devid);
                                      setUid(response.data.data[ 0 ].uid);
                                      setKey(response.data.data[ 0 ].keynum);
                                      setDate(response.data.data[ 0 ].date);
                                      setVdate(response.data.data[ 0 ].vdate);
                                      setEmail(response.data.data[ 0 ].email);
                                      setTr(response.data.data[ 0 ].id);
                                      if (
                                        response.data.data[ 0 ].pay === "payed"
                                      ) {
                                        setPurchase(true);
                                      } else {
                                        setPurchase(false);
                                      }
                                      setBegin(false);
                                    }
                                  }
                                });
                            }
                          });
                      }
                    }}
                  >
                    <i className="fa fa-at"></i>
                    Submit
                  </div>
                )
              ) : null}

            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Sonata;
