import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Icon from "../assets/icon.png";
import { Link } from "react-router-dom";
import DownloadProduct from "./DownloadProduct";
import axios from "axios";

const ViewProducts = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  server,
}) => {
  const [ products, setProducts ] = useState([]);
  const [ viewItem, setViewItem ] = useState("");
  const [ search, setSearch ] = useState("");
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const product = urlParams.get("id");
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    MsgShow(
      "normal",
      "load",
      25,
      "",
      "We are loading our stuff. Wait a moment.",
      "Please Wait..."
    );
    axios.post(server + "/get_products").then((response) => {
      if (response.data.status === "success") {
        setProducts(response.data.data);
        setTimeout(() => {
          MsgShow(
            "success",
            "success",
            5000,
            "ok",
            `${response.data.data.length < 10 ? "0" + response.data.data.length : response.data.data.length} Product(s) has been loaded. Enjoy!..`,
            "Dspace Products."
          );

        }, 2000);
      } else {
        MsgShow(
          "normal",
          "warning",
          5000,
          "ok",
          response.data.msg,
          "Our Problem"
        );
      }
    });

  }, []);
  useEffect(() => {
    if (products.length > 0) {
      if (product) {
        var vg = document.getElementById(product + "csr");
        if (isNaN(vg)) {
          vg.click();
        }
      }

    }
  }, [ products ])
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div className="headline">
          <Link className="back-btn" to={"/products"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div className="textline">
            <h2>Our Products</h2>
          </div>
          <i className="fa fa-briefcase"></i>
        </div>

        {viewItem !== "" ? (
          <DownloadProduct
            view={viewItem}
            setViewItem={setViewItem}
            setmode={setmode}
            setError={setError}
            setErrorTime={setErrorTime}
            setErrorActions={setErrorActions}
            setErrorMessage={setErrorMessage}
            setErrorTitle={setErrorTitle}
            products={products}
          />
        ) : null}
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search Products Here..."
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setSearch(e.target.value);
                } else {
                  setSearch("");
                }
              }}
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        <div className="photo-bar">
          {products.map((product) => {
            if (search.length > 0) {
              var ti = product.name.toLowerCase();
              var tx = product.deta.toLowerCase();
              var tg = product.tags.toLowerCase();
              var os = product.osvr.toLowerCase();
              if ((ti + tx + tg + os).includes(search.toLowerCase())) {
                return (
                  <div
                    className="photo-card"
                    key={product.id}
                    id={product.id + "csr"}
                    onClick={() => {
                      setViewItem(product.id);
                    }}
                  >
                    <div className="image">
                      {
                        product.cate === "Free" ? (
                          null
                        ) : (
                          <div className="free-btn">
                            <i className="fa fa-shopping-cart"></i>
                          </div>
                        )
                      }
                      <img src={product.icon} alt="" />
                    </div>
                  </div>
                );
              }
            } else {
              return (
                <div
                  className="photo-card"
                  key={product.id}
                  id={product.id + "csr"}
                  onClick={() => {
                    setViewItem(product.id);
                  }}
                >
                  <div className="image">
                    {
                      product.cate === "Free" ? (
                        null
                      ) : (
                        <div className="free-btn">
                          <i className="fa fa-shopping-cart"></i>
                        </div>
                      )
                    }
                    <img src={product.icon} alt="" />
                  </div>

                </div>
              );
            }
          })}
        </div>
        <div className="body-txt">
          <p>
            Welcome to our Free Products section, where we believe everyone
            deserves access to top-quality resources without a price tag.
            Explore our curated collection of complimentary offerings designed
            to enhance your experience and add value to your life. E-books: Dive
            into a world of knowledge with our extensive range of free e-books
            across various genres and topics. Software Trials: Test-drive our
            premium software with no commitment. Experience all the features and
            benefits at no cost. Templates: From business documents to creative
            projects, download professional-grade templates to simplify your
            tasks. Courses & Tutorials: Unlock new skills and enhance your
            expertise with our free online courses and tutorials. Tools &
            Utilities: Access a variety of handy tools and utilities to
            streamline your daily activities. Check back often as we regularly
            update our free offerings. Enjoy the freedom to explore without
            spending a dime.
          </p>
        </div>
        <div className="tags-bar">
          <ul>
            <li>
              <i className="fa fa-tag"></i>News
            </li>
            <li>
              <i className="fa fa-tag"></i>dspace
            </li>
            <li>
              <i className="fa fa-tag"></i>about
            </li>
            <li>
              <i className="fa fa-tag"></i>blog
            </li>
            <li>
              <i className="fa fa-tag"></i>explore
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ViewProducts;
