import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Icon from "../assets/scan.gif";
import { v4 as uuidV4 } from "uuid";
import emailjs from "@emailjs/browser";

const Users = ({
    setmode,
    setError,
    setErrorTime,
    setErrorActions,
    setErrorMessage,
    setErrorTitle,
    setShow,
    server,
}) => {
    const [ begin, setBegin ] = useState(true);
    const [ show2, setShow2 ] = useState(false);
    const [ Eform, setEform ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ vUser, setVuser ] = useState([]);
    const [ srchTxt, setSrchTxt ] = useState("");
    const [ subject, setSubject ] = useState("");
    const [ greet, setGreet ] = useState("");
    const [ special, setSpecial ] = useState("");
    const [ body, setBody ] = useState("");
    const [ email, setEmail ] = useState("");
    var Admin = JSON.parse(localStorage.getItem("dsp-user"));
    const MsgShow = (mode, error, time, actions, message, title, setAction) => {
        setError(error);
        setErrorActions(actions);
        setErrorTime(time);
        setErrorMessage(message);
        setErrorTitle(title);
        setmode(mode);
    };
    const ClearForm = () => {
        let form = document.getElementById("forme");
        form.reset();
    };
    useEffect(() => {
        axios.post(server + "/get_users").then((response) => {
            if (response.data.status === "success") {
                if (response.data.data) {
                    setUsers(response.data.data);

                    let usrs = 0;
                    let adms = 0;
                    response.data.data.map((val) => {
                        if (vUser) {
                            if (val.uid === vUser.uid) {
                                setVuser(val);
                            }
                        }
                        if (val.role === "client") {
                            usrs = usrs + 1;
                        } else {
                            adms = adms + 1;
                        }
                    })

                    MsgShow(
                        "success",
                        "success",
                        50,
                        "ok",
                        `We have ${usrs} Users and ${adms} Administrators. Click on each to view their profile configurations.`,
                        "Data Found."
                    );

                    return setBegin(false);

                } else {
                    setUsers(response.data.data);
                    return setBegin(false);
                }
            } else {
                setUsers(response.data.data);
                return setBegin(false);
            }
        });
    }, [ begin ]);
    const makeAdmin = (val) => {
        if (vUser.varified !== "YES") {
            MsgShow(
                "error",
                "warning",
                50,
                "ok",
                `This user isn't verified his/her profile for making him/her an admin of Dspace. Please try again after they verified their account.`,
                "User Not Verified."
            );
        } else {
            const data = {
                uid: val,
                user: vUser.user,
                pass: "20250101",
                authby: Admin.uid,
            }
            axios.post(server + "/make_admin", data).then((response) => {
                if (response.data.status === "success") {
                    MsgShow(
                        "success",
                        "success",
                        50,
                        "ok",
                        `This user has been successfully promoted as an admin of Dspace.`,
                        "User Promoted."
                    );
                    return setBegin(true);
                }
            })
        }
    }
    const demoteAdmin = (val) => {

        const data = {
            uid: val,
            authby: Admin.uid,
        }
        axios.post(server + "/demote_admin", data).then((response) => {
            if (response.data.status === "success") {
                MsgShow(
                    "success",
                    "success",
                    50,
                    "ok",
                    `This user has been successfully demoted as an client of Dspace.`,
                    "User Promoted."
                );
                return setBegin(true);
            }
        })

    }

    const deleteAccount = (val) => {
        const data = {
            uid: val,
        };
        axios
            .post(server + "/del_user", data)
            .then((response) => {
                if (response.data.status === "success") {
                    setShow2(false);
                    setVuser([]);
                    MsgShow(
                        "success",
                        "success",
                        50,
                        "close",
                        "This account has been successfully deleted. Think may you have access to do that.",
                        "Account Deleted"
                    );
                } else {
                    console.log(response.data.msg);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const sendMail = () => {
        if (subject.length > 0) {
            if (body.length > 0) {
                if (greet.length > 0) {
                    emailjs.init({
                        publicKey: "CKxlRed3oT3pLV3ZM",
                    });
                    var template_params = {
                        sub: subject,
                        greet: greet,
                        id: uuidV4(),
                        body: body,
                        special: special,
                        from: Admin.user,
                        to: email,
                    };
                    emailjs
                        .send(
                            "service_496w4t6",
                            "template_9jylc8i",
                            template_params
                        )
                        .then(
                            (response) => {
                                MsgShow(
                                    "success",
                                    "success",
                                    5000,
                                    "ok",
                                    `You have sent the E-mail to ${email} about ${subject}.`,
                                    "E-mail Sent"
                                );
                                setEform(false);
                                setShow2(true);
                                setSpecial("");
                                setSubject("");
                                setGreet("");
                                setBody("");
                                setEmail("");
                                ClearForm();
                            },
                            (error) => {
                                MsgShow(
                                    "error",
                                    "warning",
                                    5000,
                                    "close",
                                    `${error}`,
                                    "E-mail Sent Failed"
                                );
                            }
                        );
                } else {
                    MsgShow(
                        "error",
                        "warning",
                        5000,
                        "close",
                        "This e-mail needs a greeting to client. customer is the King. So be it.",
                        "Greeting Needed."
                    );
                }
            } else {
                MsgShow(
                    "error",
                    "warning",
                    5000,
                    "close",
                    "This e-mail needs a body about description of this E-mail. Please update email body.",
                    "Body Needed."
                );
            }
        } else {
            MsgShow(
                "error",
                "warning",
                5000,
                "close",
                "This e-mail needs a subject to define its use. Please update subject.",
                "Subject Needed."
            );
        }

    }
    return (
        <div className='post'>
            <div className="search-bar" style={{ zIndex: 4 }}>
                <div className="input">
                    <div className="icon">
                        <i className="fa fa-search"></i>
                    </div>
                    <input
                        type="text"
                        name="search"
                        id="search"
                        placeholder="Search anything here..."
                        onChange={(e) => {
                            if (e.target.value.length > 0) {
                                setSrchTxt(e.target.value);
                            } else {
                                setSrchTxt("");
                            }
                        }}
                    />
                    <div className="srch-btn">
                        <i className="fa fa-search"></i>
                        <p>Search</p>
                    </div>
                </div>
            </div>
            {
                show2 ?
                    <form
                        action=""
                        method="post"
                        id="myForm"
                        enctype="multipart/form-data"
                    >
                        <div class="user">
                            <div className="img">
                                <img src={vUser.photo ? vUser.photo : Icon} alt="Logo" />
                            </div>
                        </div>
                        <h4>Your Account</h4>
                        <p>
                            <span>User : </span>
                            {vUser.user}
                        </p>
                        <p>
                            <span>N.I.C : </span>
                            {vUser.nic ? vUser.nic : "Not Varified"}
                        </p>
                        <p>
                            <span>Role : </span>
                            {vUser.role === "client" ? "Client" : "Administror"}
                        </p>
                        <p>
                            <span>Email : </span>
                            {vUser.email}
                        </p>
                        <p>
                            <span>Varified : </span>
                            {vUser.varified !== "YES" ? (
                                <Link onClick={() => {
                                    emailjs.init({
                                        publicKey: "CKxlRed3oT3pLV3ZM",
                                    });
                                    var template_params = {
                                        sub: "Please Verify Your Account",
                                        greet: "Nice To See You",
                                        id: uuidV4(),
                                        body: "Unlock exclusive features and benefits by verifying your account on dspace.icu!. It's quick and easy. Join our community of verified users today and enjoy a seamless experience with enhanced security and personalized services.",
                                        special: "Visit us: https://varify.dspace.icu",
                                        from: Admin.user,
                                        to: vUser.email,
                                    };
                                    emailjs
                                        .send(
                                            "service_496w4t6",
                                            "template_9jylc8i",
                                            template_params
                                        )
                                        .then(
                                            (response) => {
                                                MsgShow(
                                                    "success",
                                                    "success",
                                                    5000,
                                                    "ok",
                                                    `You have sent the E-mail to ${vUser.email} about verifing his/her account.`,
                                                    "E-mail Sent"
                                                );
                                            },
                                            (error) => {
                                                MsgShow(
                                                    "error",
                                                    "warning",
                                                    5000,
                                                    "close",
                                                    `${error}`,
                                                    "E-mail Sent Failed"
                                                );
                                            }
                                        );
                                }}>Send Warning</Link>
                            ) : (
                                "Done!"
                            )}
                        </p>
                        <p>
                            <span>Theme : </span>
                            {vUser.theme}
                        </p>
                        <p>
                            <br />
                            <span className="green-f">
                                Protected By End to End Encryption{" "}
                                <i className="fa fa-check-circle"></i>
                            </span>
                        </p>
                        <div className="btn-bar" >
                            <div className="btn-bx cancel" onClick={() => {
                                deleteAccount(vUser.uid);
                            }}>
                                <i className='fa fa-trash'></i>
                                Remove
                            </div>
                            <div className="btn-bx close" onClick={() => {
                                setEmail(vUser.email);
                                setEform(true);
                                setShow2(false);
                            }}>
                                <i className='fa fa-warning'></i>
                                Warent
                            </div>
                            {
                                vUser.role === "admin" ?
                                    <div className="btn-bx yes" onClick={() => {
                                        demoteAdmin(vUser.uid);
                                    }}>
                                        <i className='fa fa-arrow-down'></i>
                                        Demote
                                    </div>
                                    :
                                    <div className="btn-bx yes" onClick={() => {
                                        makeAdmin(vUser.uid);
                                    }}>
                                        <i className='fa fa-arrow-up'></i>
                                        Promote
                                    </div>

                            }
                            <div className="btn-bx close" onClick={() => {
                                setVuser([]);
                                setShow2(false);
                            }}>
                                <i className='fa fa-close'></i>
                                Close
                            </div>
                        </div>
                    </form>
                    : Eform ? <form
                        action=""
                        method="post"
                        id="myForm"
                        enctype="multipart/form-data"
                    >
                        <h4> <i className="fa fa-warning" style={{ marginRight: "10px" }}></i> Warning Form</h4>


                        <div class="flex-bar">
                            <div class="input">
                                <i class="fa fa-at"></i>
                                <input
                                    type="text"
                                    name="subject"
                                    id="name"
                                    placeholder='Subject'
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            setSubject(e.target.value);
                                        }
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div class="flex-bar">
                            <div class="input">
                                <i class="fa fa-at"></i>
                                <input
                                    type="text"
                                    name="greeting"
                                    id="name"
                                    placeholder='Greeting'
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            setGreet(e.target.value);
                                        }
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div class="flex-bar">
                            <div class="input txta">
                                <i class="fa fa-flash"></i>
                                <textarea
                                    name="details"
                                    id="details"
                                    placeholder="Email Message"
                                    onChange={(e) => {
                                        setBody(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div class="flex-bar">
                            <div class="input">
                                <i class="fa fa-at"></i>
                                <input
                                    type="text"
                                    name="special_Notes"
                                    id="name"
                                    placeholder='Special Notes'
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            setSpecial(e.target.value);
                                        }
                                    }}
                                    required
                                />
                            </div>
                        </div>


                        <p>
                            <br />
                            <span className="green-f">
                                <i className="fa fa-user-shield" style={{ marginRight: "10px" }}></i>
                                Sending As : {Admin.user}@dspace.icu
                            </span>
                        </p>
                        <div className="btn-bar" >

                            <div className="btn-bx yes" onClick={() => {
                                sendMail();
                            }}>
                                <i className='fa fa-send'></i>
                                Send
                            </div>
                            <div className="btn-bx close" onClick={() => {
                                setEform(false);
                                setShow2(true);
                                setSpecial("");
                                setSubject("");
                                setGreet("");
                                setBody("");
                                setEmail("");
                            }}>
                                <i className='fa fa-close'></i>
                                Close
                            </div>
                        </div>
                    </form> : null
            }
            <div className="rates-bar">
                <div className="flex btn-bar centr" style={{ justifyContent: "center" }}>
                    {users.map((user) => {
                        if (user.email === Admin.email)
                            return (
                                <div className="r-card item" key={user.id}>
                                    <div className="logo" style={{ width: "80px", height: "80px" }}>
                                        <img src={user.photo} alt="" />
                                    </div>
                                    <div className="name">
                                        <h4>{user.user}</h4>
                                        <p>Admin</p>
                                    </div>

                                </div>
                            );
                    })}
                    {users.map((user) => {
                        if (srchTxt.length > 0) {
                            if (user.email.toLowerCase().includes(srchTxt.toLowerCase())) {
                                if (user.email !== Admin.email) {
                                    return (
                                        <div className="r-card item" key={user.id}>
                                            <div className="logo" style={{ width: "80px", height: "80px" }}>
                                                <img src={user.photo} alt="" />
                                            </div>
                                            <div className="name">
                                                <h4>{user.user}</h4>
                                                <p onClick={() => {
                                                    setVuser(user);
                                                    setShow2(true);
                                                }
                                                }>Configure</p>
                                            </div>
                                        </div>
                                    );
                                }

                            } else {
                                if (user.user.toLowerCase().includes(srchTxt.toLowerCase())) {
                                    if (user.email !== Admin.email) {
                                        return (
                                            <div className="r-card item" key={user.id}>
                                                <div className="logo" style={{ width: "80px", height: "80px" }}>
                                                    <img src={user.photo} alt="" />
                                                </div>
                                                <div className="name">
                                                    <h4>{user.user}</h4>
                                                    <p onClick={() => {
                                                        setVuser(user);
                                                        setShow2(true);
                                                    }
                                                    }>Configure</p>
                                                </div>
                                            </div>
                                        );
                                    }
                                } else {
                                }
                            }

                        } else {
                            if (user.email !== Admin.email) {
                                return (
                                    <div className="r-card item" key={user.id}>
                                        <div className="logo" style={{ width: "80px", height: "80px" }}>
                                            <img src={user.photo} alt="" />
                                        </div>
                                        <div className="name">
                                            <h4>{user.user}</h4>
                                            <p onClick={() => {
                                                setVuser(user);
                                                setShow2(true);
                                            }
                                            }>Configure</p>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    })}
                </div>
            </div>
            <div className="btn-bar">
                <div className="btn-bx close" onClick={() => {
                    setShow("");
                    setUsers([]);
                }}>
                    <i className='fa fa-close'></i>
                    Close
                </div>
            </div>
        </div>
    )
}

export default Users;