import axios from 'axios'
import React, { useEffect, useState } from 'react'

const Notifications = ({
    setNot,
    setNots,
    noti,
    nots,
    server,
    setmode,
    setError,
    setErrorTime,
    setErrorActions,
    setErrorMessage,
    setErrorTitle,
}) => {
    var user = JSON.parse(localStorage.getItem("dsp-user"));
    const [ begin, setBegin ] = useState(true);
    const deleteNoti = (val) => {
        const data = {
            id: val,
        }
        axios.post(server + "/del_noti", data).then((response) => {
            if (response.data.status === "success") {
                axios.post(server + "/get_notifications").then((response) => {
                    // handle success
                    if (response.data.status === "success") {
                        setNots(response.data.data);
                    }
                });
            }
        })
    }
    useEffect(() => {
        axios.post(server + "/get_notifications").then((response) => {
            if (response.data.status === "success") {
                setNots(response.data.data);
                setBegin(false);
            }
        });
    }, [ begin ])
    var j = 0;
    if (noti === true)
        return (
            <div className='notify'>
                <div className="ithuru" onClick={() => {
                    if (noti === true) {
                        setNot(false);
                    } else {
                        setNot(true);
                    }
                }}>

                </div>
                <div className="not-wall">
                    <div className="not-scrl">
                        {nots.map((notification) => {
                            let v = JSON.parse(notification.viewed);
                            let this_id = user.uid;
                            if (v.includes(this_id)) {
                                j = j + 1;
                            } else {
                                return (
                                    <div className="not-card" key={notification.id} onClick={() => {
                                        let v = JSON.parse(notification.viewed);
                                        let this_id = user.uid;
                                        if (!v.includes(this_id)) {
                                            const data = {
                                                id: notification.id,
                                                viewed: [ ...v, this_id ],
                                            }
                                            axios.post(server + "/viewed_notification", data).then((response) => {
                                                if (response.data.status === "success") {
                                                    axios.post(server + "/get_notifications", data).then((response) => {
                                                        // handle success
                                                        if (response.data.status === "success") {
                                                            setNots(response.data.data);
                                                        }
                                                    });
                                                }
                                            })
                                        }
                                    }}>
                                        <div className="cont">
                                            <h4><i className="fa fa-bell" style={{ marginRight: "10px" }}></i>{notification.title}</h4>
                                            <p>{notification.text} <br /><br />
                                            </p>
                                            <span>

                                                <i className="fa fa-user-shield" style={{ marginRight: "10px" }}></i>{notification.admin} &nbsp;&nbsp; <i className="fa fa-calendar" style={{ marginRight: "10px" }}></i>{notification.date}
                                            </span>

                                        </div>
                                        {
                                            notification.filter !== "all" ?
                                                <div className="btns">
                                                    <div className="btn" onClick={() => {
                                                        deleteNoti(notification.nid);
                                                    }}>
                                                        <i className='fa fa-close'></i>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                )


                            }

                        })}
                        {
                            j === nots.length ?
                                <div className="not-card" onClick={() => {
                                    axios.post(server + "/get_notifications").then((response) => {
                                        // handle success
                                        if (response.data.status === "success") {
                                            setNots(response.data.data);
                                        }
                                    });
                                }}>
                                    <div className="cont">
                                        <h4><i className="fa fa-ban" style={{ marginRight: "10px" }}></i>Notifications Empty</h4>
                                        <p>Click here to refresh!</p>
                                    </div>

                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
}

export default Notifications