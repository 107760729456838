import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function Events() {
  return (
    <div className="wall">
      <div className="post">
        <div className="logo">
          <img src="DLM.png" alt="Logo" />
        </div>
        <h4>Events And success</h4>
        <p>
          Stay tuned for a calendar packed with engaging events designed to
          inspire and entertain! From webinars and workshops to live demos and
          industry conferences, www.dspace.icu brings you opportunities to connect,
          learn, and grow. Don't miss out on our exclusive events—each one is
          crafted to offer you a unique, enriching experience. Join us and be
          part of the action!
        </p>
        <div className="btn-bar">
          <Link className="btn-bx" to={"/view_events"}>
            <i className="fa fa-star"></i> View Events
          </Link>
          <Link className="btn-bx" to={"/view_success"}>
            <i className="fa fa-trophy"></i> View Success
          </Link>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Events;
