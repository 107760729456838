import React, { useEffect, useState } from "react";
import Footer from "../pages/Footer";
import Img from "../assets/prize.png";
import axios from "axios";
const { Link } = require("react-router-dom");

const ViewSuccess = ({ server }) => {
  const [ file, setfile ] = useState([]);
  const [ begin, setbegin ] = useState(true);
  const [ search, setSearch ] = useState("");
  useEffect(() => {
    axios.post(server + "/get_succ").then((response) => {
      if (response.data.status === "success") {
        setfile(response.data.data);
        return setbegin(false);
      }
    });
  }, [ begin ]);
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div className="headline">
          <Link className="back-btn" to={"/events"}>
            <i className="fa fa-chevron-circle-left"></i>
          </Link>
          <div className="textline">
            <h2>Success</h2>
          </div>
          <i className="fa fa-trophy"></i>
        </div>
        <div className="search-bar">
          <div className="input">
            <div className="icon">
              <i className="fa fa-search"></i>
            </div>
            <input
              type="text"
              name="search"
              id="search"
              placeholder="Search Success Points..."
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setSearch(e.target.value);
                } else {
                  setSearch("");
                }
              }}
            />
            <div className="srch-btn">
              <i className="fa fa-search"></i>
              <p>Search</p>
            </div>
          </div>
        </div>
        <div className="tree-view">
          <div className="view-wall">
            {file.map((val) => {
              if (search.length > 0) {
                var ti = val.title.toLowerCase();
                var tx = val.deta.toLowerCase();
                if ((ti + tx).includes(search.toLowerCase())) {
                  return (
                    <div className="tree-item" key={val.id}>
                      <div className="line"></div>
                      <div className="line-h"></div>
                      <div className="t-card">
                        <div className="icon">
                          <img src={Img} alt="" />
                        </div>
                        <div className="text">
                          <h4>{val.title}</h4>
                          <p>{val.date}</p>
                        </div>
                      </div>
                    </div>
                  );
                }
              } else {
                return (
                  <div className="tree-item" key={val.id}>
                    <div className="line"></div>
                    <div className="line-h"></div>
                    <div className="t-card">
                      <div className="icon">
                        <img src={Img} alt="" />
                      </div>
                      <div className="text">
                        <h4>{val.title}</h4>
                        <p>{val.date}</p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewSuccess;
