import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import Img from "../assets/duruthu.png";

const AddServices = ({
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  setShow,
  server,
}) => {
  const [ begin, setBegin ] = useState(true);
  const [ Add, setAdd ] = useState(false);
  const [ Del, setDel ] = useState(false);
  const [ title, setTitle ] = useState("");
  const [ photo, setPhoto ] = useState("");
  const [ text, setText ] = useState("");
  const [ file, setFile ] = useState("");
  const [ Cate, setCate ] = useState("");
  const [ Id, setID ] = useState(uuidV4());

  const [ products, setProducts ] = useState([]);

  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    axios.post(server + "/get_services").then((response) => {
      if (response.data.status === "success") {
        setProducts(response.data.data);
      }
    });
    setBegin(false);
  }, [ begin ]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      return setP(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function setP(e) {
    setPhoto(e);
  }
  function clearF() {
    let form = document.getElementById("formcd");
    form.reset();
  }

  const upNews = () => {
    if (title.length > 0) {
      if (text.length > 0) {
        if (photo.length > 0) {
          const data = {
            title: title,
            text: text,
            icon: photo,
            sd: Id,
            link: file,
            ageLmt: Cate,
          };

          axios.post(server + "/add_service", data).then((response) => {
            if (response.data.status === "success") {
              MsgShow(
                "success",
                "success",
                5000,
                "ok",
                `You have successfully added the service. Your service will be shown in Services section.`,
                "Service Recorded."
              );
              clearF();
              setBegin(true);
            }
          });
        }
      }
    }
  };
  return (
    <>
      <div className="photo-bar">
        {products.length > 0
          ? products.map((product) => {
            return (
              <div className="photo-card" key={product.id}>
                <div className="image" style={{ position: "relative" }}>
                  {product.ageLmt <= 18 ? (
                    null
                  ) : (
                    <div className="free-btn">
                      <i className="fa fa-shield"></i>
                    </div>
                  )}
                  <img src={product.icon} alt="" />
                </div>

                {Del ? (
                  <div
                    className="del-btn"
                    onClick={() => {
                      const data = {
                        id: product.id,
                      };
                      axios
                        .post(server + "/delete_service", data)
                        .then((response) => {
                          if (response.data.status === "success") {
                            MsgShow(
                              "success",
                              "success",
                              5000,
                              "ok",
                              `You have successfully deleted the service. Your service will be removed from Services section.`,
                              "Service Deleted"
                            );
                            axios
                              .post(server + "/get_services")
                              .then((response) => {
                                if (response.data.status === "success") {
                                  setProducts(response.data.data);
                                }
                              });
                          }
                        });
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                ) : null}
              </div>
            );
          })
          : null}
        <div
          className="photo-card"
          onClick={() => {
            if (Add) {
              setAdd(false);
            } else {
              setAdd(true);
            }
          }}
        >
          <div className="image">
            <div className="text-panel">
              <i
                className="fa fa-plus-square"
                style={{ fontSize: "2rem", marginBottom: "10px" }}
              ></i>
              <h4>Service</h4>
            </div>
          </div>
        </div>
      </div>
      {Add ? (
        <form action="" method="post" id="formcd">
          <div className="news-cover">
            <img
              src={photo ? photo : Img}
              alt=""
              onClick={() => {
                document.getElementById("upload").click();
              }}
            />
          </div>
          <h4>Fill Details To Add Service Item</h4>
          <input
            type="file"
            name="upload"
            id="upload"
            onChange={(e) => {
              for (var i = 0; i < e.target.files.length; i++) {
                getBase64(e.target.files[ i ]);
              }
            }}
          />
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-shopping-cart"></i>
              <select
                name="select"
                id="name"
                onChange={(e) => {
                  setCate(e.target.value);
                }}
              >
                <option value="50">Adults</option>
                <option value="0">All</option>
              </select>
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-rocket"></i>
              <input
                type="text"
                name="title"
                id="name"
                placeholder="Service Title"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-at"></i>
              <input
                type="text"
                name="link"
                id="name"
                placeholder="Service Link"
                onChange={(e) => {
                  setFile(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex-bar">
            <div className="input">
              <i className="fa fa-key"></i>
              <input
                type="text"
                name="product_id"
                id="name"
                value={Id}
                placeholder="Product ID"
                onClick={() => {
                  setID(uuidV4());
                }}
              />
            </div>
          </div>
          <div class="flex-bar">
            <div class="input txta">
              <i class="fa fa-flash"></i>
              <textarea
                name="details"
                id="details"
                placeholder="Service Description"
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="btn-bar">
            <div
              className="btn-bx"
              onClick={() => {
                upNews();
              }}
            >
              <i className="fa fa-plus"></i>
              Add Product
            </div>
            <div
              className="btn-bx close"
              onClick={() => {
                if (Add) {
                  setAdd(false);
                } else {
                  setAdd(true);
                }
              }}
            >
              <i className="fa fa-close"></i>
              Cancel Progress
            </div>
          </div>
        </form>
      ) : (
        <div className="btn-bar">
          <div
            className="btn-bx no"
            onClick={() => {
              if (Del) {
                setDel(false);
              } else {
                setDel(true);
              }
            }}
          >
            <i className="fa fa-trash"></i>
            Delete
          </div>

          <div className="btn-bx close" onClick={() => setShow("")}>
            <i className="fa fa-close"></i>
            Close
          </div>
        </div>
      )}
    </>
  );
};

export default AddServices;
