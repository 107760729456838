import React, { useEffect, useState } from "react";

const EventItemDisplay = ({
  view,
  setViewItem,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  events,
}) => {
  const [ event, setEvent ] = useState([]);
  const MsgShow = (mode, error, time, actions, message, title) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    setEvent(events.filter((e) => {
      MsgShow(
        "success",
        "success",
        5000,
        "ok",
        "The event has been loaded successfully.",
        "Dspace Events"
      );
      return e.id === view;
    }))
  }, []);

  return (
    <div className="event-view">
      {event.length > 0
        ? event.map((eve) => {
          return (
            <div className="event-bar" key={eve.id}>
              <div className="title">
                <div className="icon">
                  <img src={eve.pic} alt="" />
                </div>
              </div>
              <div className="details">
                <h4>
                  <p>
                    {eve.title}
                  </p>
                </h4>

                <p>
                  <span>Details :</span>
                  <br /> {eve.deta}
                </p>
                <p>
                  <span>Date :</span> {eve.date}
                </p>
                <p>
                  <span>Posted By :</span> @{eve.name}
                </p>
              </div>

              <div className="btn-bar">
                <div
                  className="btn-bx"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      "https://www.dspace.icu/view_events?id=" + eve.id
                    );
                    MsgShow(
                      "success",
                      "success",
                      50,
                      "ok",
                      "Your sharing address is successfully copied. ",
                      "Dspace Share"
                    );
                  }}
                >
                  <i className="fa fa-link"></i>
                  Share
                </div>
                <div
                  className="btn-bx close"
                  onClick={() => {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const id = urlParams.get("id");
                    if (id) {
                      window.history.back();
                      setViewItem("");
                    } else {
                      setViewItem("");
                    }
                  }}
                >
                  <i className="fa fa-close"></i>
                  close
                </div>
              </div>
            </div>
          );
        })
        : null}
    </div>
  );
};

export default EventItemDisplay;
