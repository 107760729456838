import axios from 'axios';
import React, { useEffect, useState } from 'react'

const VarifyKey = ({
  server,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [ key, setKey ] = useState(urlParams.get("key"));
  const [ varified, setVarified ] = useState("not varified");
  const [ begin, setBegin ] = useState(true);
  useEffect(() => {
    const data = {
      key: key,
      mid: localStorage.getItem("m-key")
    }
    axios.post(server + "/varify_sonata", data).then((response) => {
      if (response.data.status === "success") {
        if (response.data.data[ 0 ].pay === "payed") {
          setVarified("key varified");
          setTimeout(() => {
            window.history.back();
          }, 5000);
        } else {
          setVarified("not varified");
          setTimeout(() => {
            window.history.back();
          }, 5000);
        }
      } else {
        setVarified("not varified");
        setTimeout(() => {
          window.history.back();
        }, 5000);
      }
    })
  }, [ begin ])
  return (
    <div className="flow-wall">
      <div className="scroll-wall">
        <div className="post">
          <form id="formc">
            <h4>{varified}</h4>
          </form>
        </div>
      </div>
    </div>
  )
}

export default VarifyKey;