import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
const server_url = "https://app.dspace.icu";
//const server_url = "http://localhost:3000";
function Footer() {
  var user = JSON.parse(localStorage.getItem("dsp-user"));
  return (
    <footer>
      <ul>
        <li>
          <Link to={"/terms_n_conditions"}>
            <i className="fa fa-shield"></i>
          </Link>
        </li>
        <li>
          <label
            onClick={() => {
              if (user) {
                var data = {
                  email: user.email,
                  theme: "true",
                };
                axios.post(server_url + "/theme", data).then((response) => {
                  if (response.data.status === "success") {
                    document.getElementById("theme").click();
                    localStorage.setItem("dsp-theme", "true");
                    user.theme = "true";
                    localStorage.setItem("dsp-user", JSON.stringify(user));
                  }
                });
              } else {
                document.getElementById("theme").click();
                localStorage.setItem("dsp-theme", "true");
              }
            }}
          >
            <i className="fa fa-moon moon"></i>
          </label>
          <label
            onClick={() => {
              if (user) {
                var data = {
                  email: user.email,
                  theme: "false",
                };
                axios.post(server_url + "/theme", data).then((response) => {
                  if (response.data.status === "success") {
                    document.getElementById("theme").click();
                    localStorage.setItem("dsp-theme", "false");
                    user.theme = "false";
                    localStorage.setItem("dsp-user", JSON.stringify(user));
                  }
                });
              } else {
                document.getElementById("theme").click();
                localStorage.setItem("dsp-theme", "false");
              }
            }}
          >
            <i className="fa fa-sun sun"></i>
          </label>
        </li>
        <li>
          <Link to="/terms_n_conditions?cookie_policy=true">
            <i className="fa fa-cookie"></i>
          </Link>
        </li>
        <li className="sml">
          <a >
            <i className="fa fa-copyright"></i>
          </a>
        </li>
      </ul>
      <p className="big">&copy;2024 Dark Leo</p>
      <ul>
        <li>
          <a href="tel:+94768463836">
            <i className="fa fa-phone"></i>
          </a>
        </li>
        <li>
          <a href="mailto:support@dspace.icu">
            <i className="fa fa-envelope"></i>
          </a>
        </li>
        <li>
          <a href="https://maps.app.goo.gl/fpJaqaL8twb5FbGj6">
            <i className="fa fa-location-arrow"></i>
          </a>
        </li>
        <li>
          <a href="https://github.com/darkleomotion">
            <i className="fa fa-github"></i>
          </a>
        </li>
      </ul>
    </footer>
  );
}
export default Footer;
