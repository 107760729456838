import React, { useEffect, useState } from "react";
import Icon from "../assets/icon.png";
import axios from "axios";
import { Link } from "react-router-dom";

const ViewService = ({
  view,
  setViewItem,
  pur,
  server,
  setmode,
  setError,
  setErrorTime,
  setErrorActions,
  setErrorMessage,
  setErrorTitle,
  products
}) => {
  const [ Item, setItem ] = useState([]);
  const MsgShow = (mode, error, time, actions, message, title, setAction) => {
    setError(error);
    setErrorActions(actions);
    setErrorTime(time);
    setErrorMessage(message);
    setErrorTitle(title);
    setmode(mode);
  };
  useEffect(() => {
    MsgShow(
      "normal",
      "load",
      25,
      "",
      "Please wait while we get our stuff...",
      "Dspace Services"
    );
    setItem(products.filter((p) => {
      if (p.id === view) {
        MsgShow(
          "success",
          "success",
          25,
          "ok",
          `We found ${p.title} service for you. Enjoy!..`,
          "Dspace Services"
        );
        return p.id === view;
      }
    }));
  }, []);
  const GetService = (id, link) => {
    const data = {
      id: id
    }
    axios.post(server + "/watched_sr", data).then((response) => {
      if (response.data.status === "success") {
        MsgShow(
          "success",
          "success",
          5000,
          "ok",
          "You will be redirect to your requested service destination soon... ",
          "Request Granted!"
        );
        setTimeout(() => {
          window.location.href = link;
        }, 5000);
      }
    })
  };
  return (
    <div className="event-view">
      {Item
        ? Item.map((file) => {
          return (
            <div className="event-bar" key={file.id}>
              <div className="title">
                <div className="icon">
                  <img src={file.icon !== "" ? file.icon : Icon} alt="" />
                </div>
              </div>
              <div className="details">
                <h4>
                  <p>
                    <i className="fa fa-cogs" style={{ marginRight: "10px" }}></i> {file.title}
                  </p>
                </h4>
                <p>
                  <span> <i className="fa fa-paragraph" style={{ marginRight: "10px" }}></i>  Description : </span> <br />{file.text}
                </p>
                <p>
                  <span> <i className="fa fa-eye" style={{ marginRight: "10px" }}></i> </span>{file.got < 10 ? "0" + file.got : file.got}
                </p>

              </div>

              <div className="btn-bar">
                <div
                  className="btn-bx yes"
                  onClick={() => {
                    GetService(file.id, file.link);
                  }}
                >
                  <i className="fa fa-download"></i>
                  Service
                </div>
                <div
                  className="btn-bx"
                  onClick={() => {
                    if (pur === "pur") {
                      navigator.clipboard.writeText(
                        "https://www.dspace.icu/services?id=" +
                        file.id
                      );
                      MsgShow(
                        "success",
                        "success",
                        50,
                        "ok",
                        "Your sharing address is successfully copied. ",
                        "Dspace Share"
                      );
                    } else {
                      navigator.clipboard.writeText(
                        "https://www.dspace.icu/services?id=" + file.id
                      );
                      MsgShow(
                        "success",
                        "success",
                        50,
                        "ok",
                        "Your sharing address is successfully copied. ",
                        "Dspace Share"
                      );
                    }
                  }}
                >
                  <i className="fa fa-link"></i>
                  Share
                </div>

                <div
                  className="btn-bx close"
                  onClick={() => {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const product = urlParams.get("id");
                    if (product) {
                      window.history.back();
                      setViewItem("");
                    } else {
                      setViewItem("");
                    }
                  }}
                >
                  <i className="fa fa-close"></i>
                  close
                </div>
              </div>
            </div>
          );
        })
        : null}
    </div>
  );
};

export default ViewService;
